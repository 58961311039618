
export class MembershipsBuildData{
    module = {
        name: "Memberships",
        new_name: "memberships",
        filtered_name:"Memberships",
        has_dot_menu: true,
        icon:'fa-maps',
        permission:'memberships'
    }
    
   
    
    

}