import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MenuNavService {

  menuItems:any[] = [
    {
		label: 'Dashboard',
		details: '',
		iconType: 'fi',
		iconName: 'grid',
		routerLink: '/dashboard',
		roles: 'view_dashboard'
	  },
	  {
		label: 'Memberships',
		roles: 'view_members',
		details: '',
		routerLink: '/memberships',
		iconType: 'fi',
		iconName: 'map'
	  },
	  {
		label: 'Vouchers',
		roles: 'view_members',
		details: '',
		routerLink: 'vouchers',
		iconType: 'fi',
		iconName: 'memo'
	  },
	  {
		label: 'Clients',
		roles: 'view_clients',
		routerLink: '/clients',
		iconType: 'fi',
		iconName: 'users'
	  },
	  
    
  ];
  onMenuChange:EventEmitter<any> = new EventEmitter<any>();

  navigateToPossibleRoute() {
    for (let menuItem of this.menuItems) {
      if (this.authService.hasPermission(menuItem.roles)) {
        this.router.navigate([menuItem.routerLink]);
        console.log(menuItem);
        break;
      }
    }
  }

  constructor(
    private http : HttpClient,
    private router : Router,
    private authService : AuthService
  ) { }
}
