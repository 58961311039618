import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  get(id){
	return this.http.get(`${environment.baseUrl}/user/`+id);
  }

  edit(id,formData){
	return this.http.put(`${environment.baseUrl}/user/`+id,formData);
  }

  create(formData){
	return this.http.post(`${environment.baseUrl}/user`,formData);
  }

  delete(id){
	  return this.http.delete(`${environment.baseUrl}/user/`+id);
	}

  updateProfile(formData){
	return this.http.put(`${environment.baseUrl}/user/profile`,formData);
  }


}
