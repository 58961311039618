import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";

export class ReportModel {
	total: number;
	membership_count: number;
    memberships:any[];
	vouchers:any[];
    
    constructor(input ?){
        input = input || {};
		this.total = input.total || '';
		this.membership_count = input.membership_count || '';
        this.memberships = input.memberships || [];
		this.vouchers = input.vouchers || [];;
    }

	static getMembershipsFormGroup(memberships?) {
		let fb: FormBuilder = new FormBuilder();
		
		let productsFormArray = new FormArray([]);
		let vehiclesFormArray = new FormArray([]);
		
		if (memberships.memberships.products) {
			for (let product of memberships.memberships.products) {
				productsFormArray.push(this.getProductsFormGroup(product));
			}
		}

		if (memberships.memberships.vehicles) {
			for (let vehicle of memberships.memberships.vehicles) {
				vehiclesFormArray.push(this.getVehicleFormGroup(vehicle));
			}
		}

		return fb.group({
			total: new FormControl(memberships ? memberships.total : ''),
			products: productsFormArray,
			vehicles: vehiclesFormArray
		});	
	}

	static getProductsFormGroup(product?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			total: new FormControl(product ? product.total : ''),
			title: new FormControl(product ? product.title : ''),
			membership_count: new FormControl(product ? product.membership_count : ''),
		});	
	}

	static getVehicleFormGroup(vehicle?) {
		let fb: FormBuilder = new FormBuilder();
		
		return fb.group({
			total: new FormControl(vehicle ? vehicle.total : ''),
			title: new FormControl(vehicle ? vehicle.title : ''),
			membership_count: new FormControl(vehicle ? vehicle.membership_count : ''),
		});	
	}

	static getVouchersFormGroup(vouchers?) {
		let fb: FormBuilder = new FormBuilder();
		
		let productsFormArray = new FormArray([]);
		if (vouchers.vouchers.products) {
			for (let product of vouchers.vouchers.products) {
				productsFormArray.push(this.getProductsFormGroup(product));
			}
		}
		
		return fb.group({
			total: new FormControl(vouchers ? vouchers.total : ''),
			membership_count: new FormControl(vouchers ? vouchers.membership_count : ''),
			products: productsFormArray
		});	
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		let membershipsFormArray = new FormArray([]);
		
		if (input.memberships) {
			for (let membership of input.memberships) {
				membershipsFormArray.push(this.getMembershipsFormGroup(membership));
			}
		}

		let vouchersFormArray = new FormArray([]);
		if (input.vouchers) {
			for (let voucher of input.vouchers) {
				vouchersFormArray.push(this.getVouchersFormGroup(voucher));
			}
		}

		return fb.group({
		  total: [input.total],
		  membership_count: [input.membership_count],
		  memberships:membershipsFormArray,
			vouchers:vouchersFormArray
		})
	  }
}