import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private http : HttpClient
  ) { }

    getMembership(datefrom, dateto){
      return this.http.get(`${environment.PendergardensUrl}/report/membership?date_from=` + datefrom+ `&date_to=`+dateto);
    }

	getIncome(datefrom, dateto){
		return this.http.get(`${environment.PendergardensUrl}/report/income?date_from=` + datefrom+ `&date_to=`+dateto);
	  }
    
}