import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { Location } from '@angular/common';
import { UploadService } from '../../../shared/services/upload.service';
import { ClientsModel } from '../../../shared/models/clients.model';
import { ClientsService } from '../../../shared/services/clients.service';
import { ClientsBuildData } from '../clients.build-data';

@Component({
	selector: 'app-clients-detail',
	templateUrl: './clients-detail.component.html',
	styleUrls: ['./clients-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	client: ClientsModel;

	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('sendForgotModal', { static: true }) forgotModal: ModalDirective;
	errors: String[] = [];
	data = new ClientsBuildData();
	module = this.data.module;
	form: FormGroup;
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

	constructor(
		private service: ClientsService,
		public permissionGroupService: PermissionGroupService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private uploadService: UploadService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		private store: LocalStoreService,
		private _location: Location
	) { }



	ngOnInit() {
		let _this = this;
		_this.createForm({});
		this.UserGroup();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}

		});
	}

	delpermission = true;
	UserGroup(){
		if(this.store.getItem('currentUser')['permission_group'] === 'Security'){
			this.delpermission = false;
		}
	console.log(this.store.getItem('currentUser')['permission_group'])	
	}


	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	getClientEmail() {
		let email = this.form.get('email').value;
		return email;
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}


	isTouched(controlName) {
		if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
			return true;
		}
		else {
			return false;
		}
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
	@Input() prefix: string;
	@Input() control: string;
	value: any;

	fileChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {
				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = res.isImageSaved;
				_this.form.get('profile_picture').setValue(res.cardImageBase64);
			}
			else {
				console.log("Failed to load file.");
			}
		});

		if (_this.form && _this.triggerFetch) {
			_this.triggerFetch.subscribe(res => {
				let newValue = res.get(_this.control).value;
				//console.log("New Input Value: "+newValue);
				_this.value = newValue;
			})
		}
	}



	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = ClientsModel.getFormGroup(input);

	}

	Back() {
		this._location.back();
	}

	newClient() {
		this.router.navigate(['/pender/clients-detail/new']);
	}



	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
			// console.log("l");
			//  _this.triggerFetch.emit(_this.form);
			// _this.loading.isLoading = false;
		}

		checkLoadingSpinner();


		this.service.get(id).subscribe(res => {
			_this.client = res['data'];
			console.log(_this.client);
			_this.createForm(_this.client);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.client.id, this.module.name, "/pender/clients").subscribe(res => {
			_this.isLoading = false;
		});
	}



	@Input() isLoading: boolean = false;
	save() {
		let _this = this;
		_this.isLoading = true;

		if (this.form.get('phone_number').value != null) {

			if (this.form.get('phone_number').value.length < 8) {
				_this.utils.errorMessage('Enter Proper Phone Number');
			}
		}

		if (this.form.get('phone_number').value != null) {
			if (this.form.get('phone_mobile').value.length < 8) {
				_this.utils.errorMessage('Enter Proper Phone Mobile Number');
			}
		}

		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.client, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/pender/clients']);
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}

}