import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-accepted',
  templateUrl: './accepted.component.html',
  styleUrls: ['./accepted.component.scss']
})
export class AcceptedComponent implements OnInit {
	constructor(
		private router: Router
	  ) {}
	ref = ''
	  ngOnInit() {
		this.ref = localStorage.getItem('refNumber');
		console.log(this.ref);
	  }
	 
	  
	  
	}
	