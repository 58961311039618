import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import {MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { formatDate, Location } from '@angular/common';
import { UploadService } from '../../../shared/services/upload.service';
import { MembershipsModel } from '../../../shared/models/memberships.model';
import { MembershipsBuildData } from '../memberships.build-data';
import { MembershipsService } from '../../../shared/services/memberships.service';
import { ClientsModel } from '../../../shared/models/clients.model';
import { ClientsService } from '../../../shared/services/clients.service';
import { ProductModel } from '../../../shared/models/product.model';
import { ProductService } from '../../../shared/services/product.service';
import { OrderModel } from '../../../shared/models/order.model';

@Component({
  selector: 'app-memberships-detail-new',
  templateUrl: './memberships-detail-new.component.html',
  styleUrls: ['./memberships-detail-new.component.scss']
})
export class MembershipDetailNewComponent implements OnInit {
	date = new Date();
	startdate = this.date.setDate(this.date.getDate() + 1);
	start = formatDate(this.startdate, 'yyyy-MM-dd', 'en');
	enddate = this.date.setDate(this.date.getDate() + 14);
	end = formatDate(this.enddate, 'yyyy-MM-dd', 'en');
	
  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  mem: MembershipsModel;
  _mem: MembershipsModel[] = [];
  _prod: ProductModel[] =[];
  roles: any[];
  errors: String[] = [];
  data = new MembershipsBuildData();
  module = this.data.module;
  form: FormGroup;
  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
  ctype = 'car';

  constructor(
    private service: MembershipsService,
    public permissionGroupService: PermissionGroupService,
    private router: Router,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
	private uploadService: UploadService,
	private productservice: ProductService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    private store: LocalStoreService,
	private _location: Location
  ) { }



  ngOnInit() {
	let _this = this;
	this.createForm({});
	this.getStatus();
	this.allPaymentType();
	this.allStatus();
	this.AddNewVehicle();
	//this.AllCar();
	this.router.events.subscribe(event => {
		if (event instanceof NavigationStart) {
			this.messageService.remove();
		}
	});
	// this.snapshot.paramMap.subscribe(res => {
	// 		console.log(res);
	// 		// this.loadData(res['params']['id']);
	// 		this.loadData(res['params']['id']);
		
	// })
}
	
	getStatus(){
		let _this = this;
		this.service.status();
	}

	_car=[]
	AllCar(){
		var _this = this;
		this.service.car().subscribe(res => {
			_this._car = res['data']['makes'];
		});
	}


	ptype = ''
	paymentType(event: any){
		var _this = this;
		this.ptype = event.target.value;
		if(this.ptype == 'complimentary'){
			// _this.createForm({
			// 	order_status: ['active'],
			// 	payment_status: ['complimentary']
			// })
			_this.form.get('order_status').setValue('active');
			_this.form.get('payment_status').setValue('complimentary');
		}
	}

	cartype(event: any) {
		this.ctype = event.target.value;
		this._prod = [];
		var _this = this;
		
		this.productservice.getCarType(this.ctype, {}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._prod.push(new ProductModel(uObject));
			}
		});
	}
	selectedVehicleID = null;
	AddNewVehicle() {
		var _this = this;
		_this.vehicles.push(OrderModel.getVehiclesFormGroup({
			vehicle: this.selectedVehicleID,
			make: '',
			reg_no: '',
			//_vehicle : this.getVehiclekByID(this.selectedVehicleID)
		}));
	}

	RemoveVehicle(idx){
		var _this = this;
		//array.splice(_this.vehicles.length-1, 0, 0);
		_this.vehicles.removeAt(idx);
		if(idx == 0){
			this.AddNewVehicle();
		}
	}

	price='';
	titleid = '';
	getId(event: any) {
		let _this = this;
		this.titleid = event.target.value
		_this.productservice.get(this.titleid).subscribe(res => {
			_this.price = res['data']['price']; 
			console.log(this.price);
		});
	}

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

  getMembershipEmail(){
    let email = this.form.get('email').value;
    return email;
  }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }

  pt=[]
  allPaymentType(){
	 let _this = this;
	 this.service.getAll({}).subscribe(res => {
		 for (var uObject of res['data']) {
			 _this._mem.push(new MembershipsModel(uObject));
			 _this.pt =this._mem
				  .map(item => item.payment_type)
				  .filter((value, index, self) => self.indexOf(value) === index)
		 }
	 });
  }
 
  os=[]
  allStatus(){
	 let _this = this;
	 this.service.getAll({}).subscribe(res => {
		 for (var uObject of res['data']) {
			 _this._mem.push(new MembershipsModel(uObject));
			 _this.os =this._mem
				  .map(item => item.order_status)
				  .filter((value, index, self) => self.indexOf(value) === index)
		 }
	 });
  }

  markTouched() {
    this.form.markAsTouched();
  }

  @Input() prefix:string;
  @Input() control: string;
  value: any;



  product: FormArray;
  customer: FormArray;
  vehicles: FormArray;
  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = MembershipsModel.getFormGroup(input);
	if (this.form) {
		this.product = this.form.get('product') as FormArray;
		this.customer = this.form.get('customer') as FormArray;
		this.vehicles = this.form.get('vehicles') as FormArray;
	}
  }

  Back() {
    this._location.back();
  }

  newMembership(){
	this.router.navigate(['/pender/memberships-detail/new']);
  }

  openFileUpload(ele) {
    $(ele).click();
  }


  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  isTouched(controlName) {
	if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
		return true;
	}
	else {
		return false;
	}
}


//   loadData(id) {
// 	let _this = this;
// 	let checkLoadingSpinner = () => {
// 		if (!_this.loading.isFirstLoad) {
// 			_this.loading.isFirstLoad = true;
// 			_this.loading.isLoading = true;
// 		}
// 		//  _this.triggerFetch.emit(_this.form);
//      	//  _this.loading.isLoading = false;
// 	}

// 	checkLoadingSpinner();
	

// 	this.service.get(id).subscribe(res => {
// 		_this.mem = res['data'];
// 		console.log(_this.mem);
// 		_this.createForm(_this.mem);
// 		_this.triggerFetch.emit(_this.form);
// 		_this.loading.isLoading = false;
// 	},
// 		err => {
// 			_this.loading.isLoading = false;
// 			console.log(err + " HELLO");
// 		});
// }

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }



  @Input() isLoading: boolean = false;
	save() {
	  let _this = this;
	  _this.isLoading = true;
  
	  Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
	  Utils.handleFormSave(this.form, this.service, this.mem, this.module.name, this.createForm, null).subscribe(res => {
		_this.isLoading = false;
		if (res['data']) {
		  if (_this.external == true) {
			_this.saveEvent.emit(res['data']);
		  }
		  else {
			this.router.navigate(['/pender/memberships-detail/' + res['data']['id']]);
		  }
		  _this.utils.successMessage(_this.module.name+' saved.');
		}
		else{
		  Utils.sendFormSaveError(res,this.module,this.utils);
		}
	  })
	}
}