import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class UsersModel {

  address_1: string;
  address_2: string;
  username: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone: string;
  post_code: string;
  profile_picture:string;
  groups: any[];

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
	this.username = input.username || '';
    this.first_name = input.first_name || '';
    this.last_name = input.last_name || '';
    this.email = input.email || '';
    this.post_code = input.post_code || '';
    this.phone = input.phone || '';
    this.address_2 = input.address_2 || '';
    this.address_1 = input.address_1 || '';
    this.profile_picture = input.profile_picture || '';
	this.groups = input.groups || '';
  }

  static getFormGroup(input): FormGroup {
    let fb: FormBuilder = new FormBuilder();
    return fb.group({
	  username: [input.username],
	  first_name: [input.first_name, Validators.required],
      last_name: [input.last_name, Validators.required],
      email: [input.email, Validators.required],
      password: [input.password],
      password_confirmation: [input.password_confirmation],
      address_1: [input.address_1],
      address_2: [input.address_2],
      post_code: [input.post_code],
      phone: [input.phone],
      profile_picture:[input.profile_picture],
	  groups: [input.groups ? input.groups[0].id : null, Validators.required],
    })
  }

  static getListRow(row:UsersModel) {
    return {
      id: row.id,
      email: row.email
    };
  }

}