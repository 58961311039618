import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { DashboardComponent } from './dashboard.component';


export const SimplyWhiteDashboardRoutes: Routes = [
	{
		path: '',
		component: DashboardComponent
	  },
	];
	