import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { UsersModel } from "../../../shared/models/users.model";
import { UsersService } from "../../../shared/services/users.service";
import { Utils } from "../../../shared/services/utils.service";
import { UserDetailComponent } from "../users-detail/users-detail.component";
import { UserBuildData } from "../users.build-data";


@Component({
	selector: 'app-users',
	templateUrl: './users-list.component.html',
	styleUrls: ['./users-list.component.scss']
})

export class UsersComponent implements OnInit {
	
	test = [];
	ColumnMode = ColumnMode;
	user: UsersModel[] = [];
	sort = [];
	
	ngOnInit(){}

	columnsDynamic = [
		{
			name : 'Id',
			flexGrow: 1,
			sortable: true
		},
		{
			name : 'username',
			flexGrow: 3,
			sortable: true
		},{
			prop : 'first_name',
			flexGrow: 3,
			sortable: true
		}
	]

	rowCount = 0;
	curPage = 0;
	pageSize = 25;
	constructor(private router: Router, 
		public userService: UsersService){
			var _this = this;
			this.userService.getAll({}).subscribe(res => {
				for (var uObject of res['data']) {
					_this.user.push(new UsersModel(uObject));
					this.test = this.user;
				}
				_this.rowCount = res['meta']['total'];
			_this.curPage = 0;
			console.log(this.test);
			});
		
	}

	
	data = new UserBuildData();
  	//module = this.data.module; 
	//activeRows: Map<number, any> = new Map<number, any>();
	//@Input() rowFunction;

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	onSort(event) {
		let _this = this;
		this.user = [];
		console.log('Sort Event', event);
		_this.loading.isLoading = true;
		if(event.sorts['0']['prop'] == 'first_name'){
			setTimeout(() => {
			
				this.userService.order(event.newValue, 'name').subscribe(res => {
				  for (var uObject of res['data']) {
					  
					  _this.user.push(new UsersModel(uObject));
					  this.sort = this.user;
					  this.test = this.sort;
					  
				  }
			  });
			  
			  }, 1000);
		  }else{
			setTimeout(() => {
			
				this.userService.order(event.newValue, event.sorts['0']['prop']).subscribe(res => {
				  for (var uObject of res['data']) {
					  
					  _this.user.push(new UsersModel(uObject));
					  this.sort = this.user;
					  this.test = this.sort;
					  
				  }
			  });
			  
			  }, 1000);
		  }
	  }
	
	  page: UsersModel[] = [];
	setPage (event): void {
		let _this = this;
		this.test = [];
		this.page = [];
		console.log(event); 
		
		this.curPage =event['page'] -1;
		this.userService.page(event['page']).subscribe(res => {
			
			for (var uObject of res['data']) {
				
				_this.page.push(new UsersModel(uObject));
				_this.test = _this.page;
				//console.log(this.test);
			}
		});

		
		
	  }

	newUser(){
    	this.router.navigate(['/pender/users-detail/new']);
	}

	onActivate(event) {
		if(event.type == 'click') {
			this.router.navigate(['/pender/users-detail/' +event.row.id]);
			//UserDetailComponent
		}
	}


	tablestyle = 'bootstrap';

}
