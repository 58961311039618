import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class ProductModel {
	
	id: number;
  title: string;
  tax_rate: string;
  price: string;
  membership_months: string;
  vehicle_type: string;
  is_membership: number;

  constructor(input?) {
    input = input || {};
    this.id = input.id || '';
	this.title = input.title || '';
    this.tax_rate = input.tax_rate || '';
    this.price = input.price || '';
    this.membership_months = input.membership_months || '';
	this.vehicle_type = input.vehicle_type || '';
    this.is_membership = input.is_membership || '';
  }

  static getFormGroup(input): FormGroup {
    let fb: FormBuilder = new FormBuilder();
    return fb.group({
	  title: [input.title],
      tax_rate: [input.tax_rate],
      price: [input.price],
      membership_months: [input.membership_months],
	  vehicle_type: [input.vehicle_type],
      is_membership: [input.is_membership],
    })
  }

}