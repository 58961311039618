import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import {MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { formatDate, Location } from '@angular/common';
import { MembershipsModel } from '../../../shared/models/memberships.model';
import { MembershipsService } from '../../../shared/services/memberships.service';
import { VoucherBuildData } from '../vouchers.build-data';
import { ProductModel } from '../../../shared/models/product.model';
import { ProductService } from '../../../shared/services/product.service';
import { OrderModel } from '../../../shared/models/order.model';
import { ClientsService } from '../../../shared/services/clients.service';
import { ClientsModel } from '../../../shared/models/clients.model';
import { VoucherModel } from '../../../shared/models/voucher.model';

@Component({
  selector: 'app-vouchers-detail-new',
  templateUrl: './vouchers-detail-new.component.html',
  styleUrls: ['./vouchers-detail-new.component.scss']
})
export class VoucherDetailNewComponent implements OnInit {
	
	date = new Date();
	startdate = this.date.setDate(this.date.getDate() + 1);
	start = formatDate(this.startdate, 'yyyy-MM-dd', 'en');
	enddate = this.date.setDate(this.date.getDate() + 14);
	end = formatDate(this.enddate, 'yyyy-MM-dd', 'en');

  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  mem: VoucherModel;
 _client: ClientsModel[] =[];
 _company: ClientsModel[] =[];
  _mem: MembershipsModel[] = [];
  _prod: ProductModel[] =[];
  roles: any[];
  errors: String[] = [];
  data = new VoucherBuildData();
  module = this.data.module;
  form: FormGroup;
  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

  constructor(
    private service: MembershipsService,
    public permissionGroupService: PermissionGroupService,
    private router: Router,
	private route: ActivatedRoute,
    private snapshot: ActivatedRoute,
    private authService: AuthService,
	private customerService: ClientsService,
	private productservice: ProductService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    private store: LocalStoreService,
	private _location: Location
  ) { }



  ngOnInit() {
	let _this = this;
	this.createForm({});
	this.Product() ;
	this.Company();
	// this.Customer();
	this.allProducts();
	this.allStatus();
	this.router.events.subscribe(event => {
		if (event instanceof NavigationStart) {
			this.messageService.remove();
		}
	});
	// this.route.queryParamMap.subscribe(res => {
	// 	if(res['params']['company_name']) {
	// 		this.customerService.getAll({}).subscribe(res => {
	// 			console.log(res['data']);
	// 				_this.createForm({
	// 					first_name: res['data']['first_name'],
	// 					last_name: res['data']['last_name'],
	// 					//id_card: res['data']['id_card'],
	// 					phone_mobile: res['data']['phone_mobile'],
	// 					email: res['data']['email'],
	// 					address_1: res['data']['address_1'],
	// 					address_2: res['data']['address_2'],
	// 				});
	// 		});
	// 	}
	// });
}
	
	

  hasPermission(permission) {
    return this.authService.hasPermission(permission);
  }

//   getMembershipEmail(){
//     let email = this.form.get('email').value;
//     return email;
//   }

  isFormValueChecked(controlName) {
    return this.form.get(controlName).value;
  }


 allProducts(){
	let _this = this;
	_this.productservice.getAll({}).subscribe(res => {
		
			_this._prod.push(new ProductModel(res['title']));
			
	});
 }

 os=[]
 allStatus(){
	let _this = this;
	_this.service.status().subscribe(res => {
		
			_this._mem.push(new MembershipsModel(res['data']));
		
		
	});
 }

 Product(){
	let _this = this;
	this.productservice.getProduct(0, {}).subscribe(res => {
		for (var uObject of res['data']) {
			_this._prod.push(new ProductModel(uObject));
		}
	});
 }


 Company(){
	var _this = this;
	this.customerService.getCompany({}).subscribe(res => {
		for (var uObject of res['data']) {
			_this._company.push(new ClientsModel(uObject));
		}
	});
 }

 companyid=''
 getIdCompany(event: any){
	let _this = this;
		this.companyid = event.target.value
		console.log(this.companyid);
		if(this.companyid == 'new'){
			_this.createForm({
				first_name: [''],
				last_name: [''],
				id_card: [''],
				company_name: [''],
				phone_number: [''],
				email: [''],
				address_1: [''],
				address_2: [''],
			});
		}else{
		this.customerService.get(this.companyid).subscribe(res => {
			_this.createForm({
				first_name: res['data']['first_name'],
				last_name: res['data']['last_name'],
				id_card: res['data']['id_card'],
				company_name: res['data']['company_name'],
				phone_number: res['data']['phone_number'],
				email: res['data']['email'],
				address_1: res['data']['address_1'],
				address_2: res['data']['address_2'],
			});
		});
	}
 }

 	price='';
	titleid = '';
	getId(event: any) {
		let _this = this;
		this.titleid = event.target.value
		_this.productservice.get(this.titleid).subscribe(res => {
			_this.price = res['data']['price']; 
			console.log(this.price);
		});
	}

  markTouched() {
    this.form.markAsTouched();
  }


  @Input() prefix:string;
  @Input() control: string;
  value: any;

  createForm(input, form?) {
    input = input || {};
    this.form = VoucherModel.getFormGroup(input);
	
  }

  Back() {
    this._location.back();
  }



  openFileUpload(ele) {
    $(ele).click();
  }


  loading = {
    isFirstLoad: false,
    isLoading: false
  };

  loadData(id) {
	let _this = this;
	let checkLoadingSpinner = () => {
		if (!_this.loading.isFirstLoad) {
			_this.loading.isFirstLoad = true;
			_this.loading.isLoading = true;
		}
		//  _this.triggerFetch.emit(_this.form);
     	//  _this.loading.isLoading = false;
	}

	checkLoadingSpinner();
	

	this.service.get(id).subscribe(res => {
		_this.mem = res['data'];
		console.log(_this.mem);
		_this.createForm(_this.mem);
		_this.triggerFetch.emit(_this.form);
		_this.loading.isLoading = false;
	},
		err => {
			_this.loading.isLoading = false;
			console.log(err + " HELLO");
		});
}

  //Variable & Function for <phone-input>
  triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  updateForm(event) {
    this.form = event;
  }

  isTouched(controlName) {
	if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
		return true;
	}
	else {
		return false;
	}
}

  @Input() isLoading: boolean = false;
	save() {
	  let _this = this;
	  _this.isLoading = true;
  
	  Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
	  Utils.handleFormSave(this.form, this.service, this.mem, this.module.name, this.createForm, null).subscribe(res => {
		_this.isLoading = false;
		if (res['data']) {
		  if (_this.external == true) {
			_this.saveEvent.emit(res['data']);
		  }
		  else {
			this.router.navigate(['/pender/vouchers-detail/' + res['data']['id']]);
		  }
		  _this.utils.successMessage(_this.module.name+' saved.');
		}
		else{
		  Utils.sendFormSaveError(res,this.module,this.utils);
		}
	  })
	}
}