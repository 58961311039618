import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { MembershipsModel } from "../../../shared/models/memberships.model";
import { ProductModel } from "../../../shared/models/product.model";
import { MembershipsService } from "../../../shared/services/memberships.service";
import { ProductService } from "../../../shared/services/product.service";
import { VoucherBuildData } from "../vouchers.build-data";

interface Itable{
	id: string;
	first_name: string;
	username: string;
}

@Component({
	selector: 'app-vouchers-list',
	templateUrl: './vouchers-list.component.html',
	styleUrls: ['./vouchers-list.component.scss']
  })

export class VoucherComponent implements OnInit {

	test = [];
	sort = [];
	ColumnMode = ColumnMode;
	mem: MembershipsModel[] = [];
	
	ngOnInit(){}

	columnsDynamic = [
		{
			name : 'order_name',
			flexGrow: 1,
			sortable: true
		},
		{
			prop : 'first_name',
			flexGrow: 3,
			sortable: true
		},{
			prop : 'order_status',
			flexGrow: 3,
			sortable: true
		},
		{
			prop : 'order_status',
			flexGrow: 3,
			sortable: true
		},{
			prop : 'payment_status',
			flexGrow: 3,
			sortable: true
		}
	]

	rowCount = 0;
	curPage = 0;
	pageSize = 25;

	constructor(private router: Router, 
		public memService: MembershipsService){
			var _this = this;


			this.memService.getType(0).subscribe(res => {
				for (var uObject of res['data']) {
					_this.mem.push(new MembershipsModel(uObject));
					this.test = this.mem;
				}
				console.log(this.test);
				this.rowCount = res['meta']['total'];
			_this.curPage = 0;
			console.log(this.test);
			});
		
	}

	
	data = new VoucherBuildData();

	
	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	onSort(event) {
		let _this = this;
		this.mem = [];

		
		console.log('Sort Event', event);
		_this.loading.isLoading = true;
	
		if (event.sorts['0']['prop'] == 'first_name') {
			setTimeout(() => {

				this.memService.orderVoucher(event.newValue, 'name').subscribe(res => {
					for (var uObject of res['data']) {

						this.mem.push(new MembershipsModel(uObject));
						this.sort = this.mem;
						this.test = this.sort;

					}
				});

			}, 1000);
		}
		else if (event.sorts['0']['prop'] == 'product.title') {
			setTimeout(() => {

				this.memService.orderVoucher(event.newValue, 'product').subscribe(res => {
					for (var uObject of res['data']) {

						_this.mem.push(new MembershipsModel(uObject));
						this.sort = this.mem;
						this.test = this.sort;

					}
				});

			}, 1000);
		} else {
			setTimeout(() => {

				this.memService.orderVoucher(event.newValue, event.sorts['0']['prop']).subscribe(res => {
					for (var uObject of res['data']) {

						_this.mem.push(new MembershipsModel(uObject));
						this.sort = this.mem;
						this.test = this.sort;

					}
				});

			}, 1000);
		}
	
	}
	page: MembershipsModel[] =[]
	setPage (event): void {
		let _this = this;
		this.test = [];
		this.page = [];
		console.log(event['page']); 
		
		this.curPage =event['page'] -1;
		this.memService.pageVoucher(event['page']).subscribe(res => {
			
			for (var uObject of res['data']) {
				
				_this.page.push(new MembershipsModel(uObject));
				_this.test = _this.page;
			}
		});

		console.log(this.test);
		
	  }

	newVoucher(){
    	this.router.navigate(['/pender/vouchers-detail/new']);
	}

	onActivate(event) {
		if(event.type == 'click') {
			this.router.navigate(['/pender/vouchers-detail/' +event.row.id]);
			//UserDetailComponent
		}
	}


	tablestyle = 'bootstrap';

}
