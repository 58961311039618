import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { ClientDetailComponent } from './clients-detail/clients-detail.component';
import { ClientsComponent } from './clients-list/clients-list.component';


export const ClientsRoutes: Routes = [
  {
     
    path: '',
    component: ClientsComponent
  
  },
  {
	path: 'new',
	canActivate: [AuthGaurd,RoleGuard],
	component: ClientDetailComponent
  },
  {
	path: ':id',
	canActivate: [AuthGaurd,RoleGuard],
	component: ClientDetailComponent
  },
];
