import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

export class OrderModel {

	id: number;
	address_1: string;
	address_2: string;
	product: number;
	make: string;
	reg_no: string;
	vehicle_type:string;
	email: string;
	first_name: string;
	last_name: string;
	post_code: string;
	payment_type: string;
	membership_no: string;
	valid_from: string;
	phone_number: number;
	id_card: string;
	order_status: string;
	order_number:string;
	total_price:string;
	vehicles: any[];

	constructor(input?) {
		input = input || {};
		this.id = input.id || '';
		this.product = input.product || '';
		this.vehicle_type = input.vehicle_type || '';
		this.make = input.make || '';
		this.reg_no = input.reg_no || '';
		this.first_name = input.first_name || '';
		this.last_name = input.last_name || '';
		this.email = input.email || '';
		this.post_code = input.post_code || '';
		this.payment_type = input.payment_type || '';
		this.address_2 = input.address_2 || '';
		this.address_1 = input.address_1 || '';
		this.membership_no = input.membership_no || '';
		this.valid_from = input.valid_from || '';
		this.phone_number = input.phone_number || '';
		this.id_card = input.id_card || '';
		this.order_status = input.order_status || '';
		this.order_number = input.order_number || '';
		this.total_price = input.total_price || '';
	}

	static getVehiclesFormGroup(vehicles?) {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			make: new FormControl(vehicles ? vehicles.make : 1, Validators.required),
			reg_no: new FormControl(vehicles ? vehicles.reg_no : '', Validators.required),
		});
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		let VehiclesFormArray = new FormArray([]);
		if (input.vehicles) {
			for (let vehicle of input.vehicles) {
				VehiclesFormArray.push(this.getVehiclesFormGroup(vehicle));
			}
		}

		return fb.group({
			id: [input.id],
			product: [input.product, Validators.required],
			vehicle_type: [input.vehicle_type, Validators.required],
			make: [input.make],
			reg_no: [input.reg_no],
			first_name: [input.first_name, Validators.required],
			last_name: [input.last_name, Validators.required],
			email: [input.email, Validators.required],
			address_1: [input.address_1, Validators.required],
			address_2: [input.address_2],
			post_code: [input.post_code],
			payment_type: [input.payment_type],
			membership_no: [input.membership_no],
			valid_from: [input.valid_from],
			phone_number: [input.phone_number, Validators.required],
			id_card: [input.id_card, Validators.required],
			order_status: [input.order_status],
			order_number: [input.order_number],
			total_price: [input.total_price],
			vehicles: VehiclesFormArray
		})
	}


}