import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { UserDetailComponent } from './users-detail/users-detail.component';
import { UsersComponent } from './users-list/users-list.component';


export const UsersRoutes: Routes = [
	{
		path: '',
		component: UsersComponent
	  },
	  {
		path: 'new',
		canActivate: [AuthGaurd,RoleGuard],
		component: UserDetailComponent
	  },
	  {
		path: ':id',
		canActivate: [AuthGaurd,RoleGuard],
		component: UserDetailComponent
	  },
];
