import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RoleService } from './role.service';
import * as $ from 'jquery';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';
import { MenuNavService } from '../services/menu-navigation.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { SimplyWhiteLayout } from '../../@pages/layouts';

@Injectable()
export class RoleGuard implements CanActivate {
  returnUrl: string;

  constructor(
    private permissionService : NgxPermissionsService,
    private router: Router,
    private roleService : RoleService,
    private messageService : MessageService,
    private authService : AuthService,
   // private menuService : SimplyWhiteLayout
  ) { }

  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log(next);
    let _this = this;
    
	

    if ($('form').hasClass("ng-touched") && $('form').hasClass("blockAuto")) {
      return false;
    }

    if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
      console.log("has touched form!");
	}
      
   

    if (next.data.permission == 'generate_report') {
      if (next.url.length == 0) {

        if(localStorage.getItem('activeMenu')){
          //this.menuService.navigateToPossibleRoute();
        }
        
      }
      return true;
    }

    console.log(this.permissionService.getPermissions());
    console.log("Has Permission: ",next.data.permission_name," "+this.authService.hasPermission(next.data.permission));
	

    if (!next.data.permission){
      return true;
    }

    if (!this.authService.hasPermission(next.data.permission)) {
      console.warn('Access Denied. Navigated to default route');
      let path = _this.roleService.getDefaultRoute(_this.authService.getUserRole());
      _this.router.navigate(['/pender/memberships']);
      return false;
    }

    else if (this.permissionService.hasPermission(next.data.permission)) {
      return true;
    }
    
    else {
      console.log(`Permission/Role not recognized`);
      return false;
    }

    return true;

    // if (userRole) {
    //   if (userRole.routeAccess[state.url.split('/')[1]]) {
    //     return true;
    //   }
    //   console.warn('Access Denied. Navigated to default route');

    //   const defaultRoute = this.rolesService.getAccess(userRoleId).defaultRoute;
    //   this.router.navigate([`/${defaultRoute}`]);
    //   return false;
    // } else {
    //   console.log(`User roles: [${userRole}] not recognized`);
    //   return false;
    // }

  }
}

