import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class MembershipsService {

	constructor(
		private http: HttpClient
	) { }

	getAll(searchParams) {
		return this.http.get(`${environment.PendergardensUrl}/order`, { params: searchParams });
	}

	get(id) {
		return this.http.get(`${environment.PendergardensUrl}/order/` + id);
	}

	getType(membership) {
		return this.http.get(`${environment.PendergardensUrl}/order?is_membership=` + membership);
	}

	getPublicType(membership) {
		return this.http.get(`${environment.PendergardensUrl}/public/order?is_membership=` + membership);
	}

	create(formData) {
		return this.http.post(`${environment.PendergardensUrl}/order`, formData);
	}

	createPublic(formData){
		return this.http.post(`${environment.PendergardensUrl}/public/order`, formData);
	}

	activate(order, FormData) {
		return this.http.post(`${environment.PendergardensUrl}/order/` + { order } + '/activate', FormData);
	}

	reference(reference) {
		return this.http.get(`${environment.PendergardensUrl}/order/reference/` + reference);
	}

	status() {
		return this.http.get(`${environment.PendergardensUrl}/order/status`);
	}

	renewal(membershipNumber, email) {
		return this.http.get(`${environment.PendergardensUrl}/public/order?membership_no=` + membershipNumber + `&email=` + email);
	}

	edit(order, FormData) {
		return this.http.put(`${environment.PendergardensUrl}/order/` + order, FormData);
	}

	car(){
		return this.http.get(`${environment.PendergardensUrl}/public/vehiclemake`);
	}

	orderMemebership(order_direction, sort){
		return this.http.get(`${environment.PendergardensUrl}/order?is_membership=1&order_asc_desc=` + order_direction+`&order_by=` + sort);
	}

	orderVoucher(order_direction, sort){
		return this.http.get(`${environment.PendergardensUrl}/order?is_membership=0&order_asc_desc=` + order_direction+`&order_by=` + sort);
	}

	pageMembership(page){
		return this.http.get(`${environment.PendergardensUrl}/order?is_membership=1&page=` +page);
	}

	pageVoucher(page){
		return this.http.get(`${environment.PendergardensUrl}/order?is_membership=0&page=` +page);
	}
}
