import { C } from '@angular/cdk/keycodes';
import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { typeSourceSpan } from '@angular/compiler';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { event } from 'd3';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { StripeService } from 'ngx-stripe';
import { MessageService } from '../../@pages/components/message/message.service';
import { PaginatedSelectComponent } from '../../@pages/components/paginated-select/paginated-select.component';
import { OrderModel } from '../../shared/models/order.model';
import { ProductModel } from '../../shared/models/product.model';
import { VehicleModel } from '../../shared/models/vehicle.model';
import { CheckoutService } from '../../shared/services/checkout.service';
import { OrderService } from '../../shared/services/order.service';
import { ProductService } from '../../shared/services/product.service';
import { Utils } from '../../shared/services/utils.service';
import { Location } from '@angular/common';
import { ClientsService } from '../../shared/services/clients.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
 import { StripeModule, StripeScriptTag } from "stripe-angular"
// import { Stripe } from 'stripe';
 import {loadStripe} from '@stripe/stripe-js';


@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
	date = new Date();
	startdate = this.date.setDate(this.date.getDate() + 1);
	start = formatDate(this.startdate, 'yyyy-MM-dd', 'en');
	enddate = this.date.setDate(this.date.getDate() + 14);
	end = formatDate(this.enddate, 'yyyy-MM-dd', 'en');


	@ViewChild('addVehicleModal', { static: true }) modalAddVehicle: ModalDirective;
	@ViewChild('deleteVehicleModal', { static: true }) modalDeleteVehicle: ModalDirective;
	@ViewChild('payModal', { static: true }) modalPay: ModalDirective;
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	form: FormGroup;
	vech: FormGroup;
	order: OrderModel;
	// data = new DetailBuildData();
	// module = this.data.module;
	paymentHandler: any = null;
	_prod: ProductModel[] = [];
	_mem: ProductModel[] = [];
	clientsecret

	paymentclicked = false

	
	paymentType = localStorage.getItem("paymenttype");
	success: boolean = false;
	failure: boolean = false
	_vehicle: VehicleModel[] = [];
	membership_no='';
	ctype = '';
	titleid = '';
	price = '';
	producttitle
	checkValue = false;
	i = 0;
	motorbool = false;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpClient,
		private stripeService: StripeService,
		private orderservice: OrderService,
		private snapshot: ActivatedRoute,
		private utils: Utils,
		private productservice: ProductService,
		private errorHandler: ErrorHandlingService,
		private clientservice: ClientsService,
		private messageService: MessageService,
		private _location: Location,
		private stripeScriptTag: StripeScriptTag) {
			if (!this.stripeScriptTag.StripeInstance) {
				this.stripeScriptTag.setPublishableKey('pk_test_51Kpu3KAuEKeMv3jzLh8gXPOedgWwXn6nxyROWNXGmLYX5rg4i9LVpXGPclXfa8ArQwkB186nZM3qhKFMN9SxviHW001dbPO7n5');
			  }
		 }

	ngOnInit() {
		let _this = this;
		
		this.createForm({});
		this.isProduct();
		this.AddNewVehicle();
		this.PaymentType();
		this.AllCarr();
		console.log(this.start);
		console.log(this.end);
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.route.queryParamMap.subscribe(res => {
			if (res['params']['order_number'] && !_this.external) {
				this.loadData(res['params']['order_number']);
			}

			if (res['params']['membership_no']) {
				_this.orderservice.renewal(res['params']['membership_no'], res['params']['email']).subscribe(res => {
					//this.isProduct();
					console.log(res['data']);
					
					//this.form.get(this.titleid).setValue(res['data']['product']['id']);
					_this.createForm({
						first_name: res['data']['customer']['first_name'],
						last_name: res['data']['customer']['last_name'],
						id_card: res['data']['customer']['id_card'],
						phone_number: res['data']['customer']['phone_number'],
						email: res['data']['customer']['email'],
						address_1: res['data']['customer']['address_1'],
						address_2: res['data']['customer']['address_2'],
						post_code: res['data']['customer']['post_code'],
						membership_no: res['data']['membership_no'],
						valid_from: res['data']['customer']['valid_to'],
						total_price: res['data']['total_price'],
						vehicle_type: res['data']['product']['vehicle_type'],
						product: res['data']['product']['id'],
					
						
					});
					_this.price = res['data']['total_price'];
					_this.ctype = res['data']['product']['vehicle_type'];
					_this.getCarType();
					for (var uObject of res['data']['vehicles']) {
						_this.vehicles.push(OrderModel.getVehiclesFormGroup({
							make: uObject['make'],
							reg_no: uObject['reg_no'],
							
						}));
					}
				});
			}
		});
	}

	Back() {
		this._location.back();
	}

	Pay() {

		this.router.navigate(['/pender/checkout']);
	}

	cartype(event: any) {
		this.ctype = event.target.value;
		this._mem = [];
		var _this = this;
		_this.getCarType();
		

	}

	getCarType(){
		var _this = this;
		this.productservice.getPublicCarType(this.ctype, {}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._mem.push(new ProductModel(uObject));
			}
		});

		if (_this.ctype == 'motorbike') {
			//console.log('hello');
			//document.getElementById('check').setAttribute('style', 'opacity: 1');
			this.motorbool = true
			//document.getElementById("myCheck").checked = true;

		} else if (_this.ctype == 'car') {
			//console.log('hello');
			//document.getElementById('check').setAttribute('style', 'opacity: 0');
			this.motorbool = false
		}
	}

	
	getId(event: any) {
		let _this = this;
		this.titleid = event.target.value
		_this.productservice.publicGet(this.titleid).subscribe(res => {
			_this.price = res['data']['price'];
			_this.producttitle = res['data']['title'];
			console.log(this.price);
		});
	}

	vehicles: FormArray;
	createForm(input, form?, vech?) {
		input = input || {};
		form ? form : this.form = OrderModel.getFormGroup(input);
		if (this.form) {
			this.vehicles = this.form.get('vehicles') as FormArray;
			vech ? vech : this.vech = OrderModel.getVehiclesFormGroup(this.form.get('vehicles'))
		}
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};


	loadData(order_number) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
			// _this.triggerFetch.emit(_this.form);
			// _this.loading.isLoading = false;
		}

		checkLoadingSpinner();


		this.orderservice.get(order_number).subscribe(res => {
			_this.order = res['data'];
			console.log(_this.order);
			_this.createForm(_this.order);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},

			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	

	isTouched(controlName) {
		// if (this.vech.get(controlName).touched && this.vech.get(controlName).invalid ) {
		// 	return true;
		// }
		if (this.form.get(controlName).touched && this.form.get(controlName).invalid ) {
			return true;
		}
		
		else {
			return false;
		}
	}

	_car = []
	AllCarr() {
		var _this = this;
		this.orderservice.car().subscribe(res => {
			_this._car = res['data']['makes'];
		});
	}

	PaymentType() {
		let _this = this;
		_this.form.get('payment_type').setValue('card');
	}

	isProduct() {
		var _this = this;
		this.productservice.getAllPublic({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._prod.push(new ProductModel(uObject));
			}
		});
	}

	d = 1;
	//d = localStorage.getItem('ismem');

	// isMembership(){
	// 	var _this = this;
	// 	this.productservice.get('').subscribe(res => {
	// 		for (var uObject of res['data']) {
	// 			_this._mem.push(new ProductModel(uObject));
	// 		}
	// 	});
	// }


	// isType() {
	// 	var _this = this;
	// 	this.productservice.getCarType(this.ctype, {}).subscribe(res => {
	// 		for (var uObject of res['data']) {
	// 			_this._mem.push(new ProductModel(uObject));
	// 		}
	// 	});
	// }


	
	selectedVehicleID = null;
	AddNewVehicle() {
		var _this = this;
		_this.vehicles.push(OrderModel.getVehiclesFormGroup({
			vehicle: this.selectedVehicleID,
			make: '',
			reg_no: '',
			//_vehicle : this.getVehiclekByID(this.selectedVehicleID)
		}));
	}

	RemoveVehicle(idx) {
		var _this = this;
		//array.splice(_this.vehicles.length-1, 0, 0);
		_this.vehicles.removeAt(idx);
		if (idx == 0) {
			this.AddNewVehicle();
		}
	}

	selectedVehicle: string = '';
	selectVehicleChange(event: any) {
		this.selectedVehicle = event.target.value;
	}

	checkbox = false;
	Check(event) {
		var _this = this;
		if (event.target.checked) {
			this.checkbox = true;
			console.log(this.checkbox);
		} else {
			this.checkbox = false;
			console.log(this.checkbox);
			//_this.utils.errorMessage(' Checkbox is not ticked');
		}
	}

	checking() {
		var _this = this;
		console.log('test');
		if (this.ctype == 'motorbike' && this.checkbox == false) {
			console.log('test1');
			_this.utils.errorMessage(' Checkbox is not ticked');
		}
	}



	refNumber = ''
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	@Input() external: boolean = false;
	@Input() isLoading: boolean = false;


	// handler = stripe.elements({
	// 	key: 'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
	// 	locale: 'auto',
	// 	email: 'stripey@mcstripeface.com',
	// 	token: function (token) {
	// 		// Use the token to create the charge with a server-side script.
	// 		// You can access the token ID with `token.id`
	// 	}
	// });


	//  handleSubmit(e) {
	// 	let _this = this;
	// 	e.preventDefault();
	// 	this.isLoading = true;
	  
	// 	const { error } = Stripe.confirmPayment({
	// 		element: this.clientsecret,
	// 	  confirmParams: {
	// 		// Make sure to change this to your payment completion page
	// 		return_url: "http://localhost:4200/pender/success",
	// 	  },
	// 	});
	  
	// 	if (error.type === "card_error" || error.type === "validation_error") {
	// 	  this.showMessage(error.message);
	// 	} else {
	// 	  this.showMessage("An unexpected error occurred.");
	// 	}
	  
	// 	_this.isLoading = false;
	//   }


	// showMessage(messageText) {
	// 	const messageContainer = document.querySelector("#payment-message");
	  
	// 	messageContainer.classList.remove("hidden");
	// 	messageContainer.textContent = messageText;
	  
	// 	setTimeout(function () {
	// 	  messageContainer.classList.add("hidden");
	// 	  messageText.textContent = "";
	// 	}, 4000);
	//   }


	cardCaptureReady = false

	onStripeInvalid( error: Error ){
	  console.log('Validation Error', error)
	}
  
	onStripeError( error: Error ){
	  console.error('Stripe error', error)
	}
  
	setPaymentMethod( token: stripe.paymentMethod.PaymentMethod ){
	  console.log('Stripe Payment Method', token)
	}
  
	setStripeToken( token: stripe.Token ){
	  console.log('Stripe Token', token)
	  this.router.navigate(['/pender/accepted']);
	}
  
	setStripeSource( source: stripe.Source ){
	  console.log('Stripe Source', source)
	}


	static forceTouchPaginatedSelects(paginated_selects: PaginatedSelectComponent[]) {
		if (paginated_selects) {
		  for (let paginatedSelect of paginated_selects['_results']) {
			let select: PaginatedSelectComponent = paginatedSelect;
			if (select && select.pg_select) {
			  select.form.markAllAsTouched();
			}
		  }
		}
	  }

	static checkFormValidity(form: FormGroup, paginatedSelects?: PaginatedSelectComponent[]): boolean {
		form.updateValueAndValidity();
		if (paginatedSelects) {
		  this.forceTouchPaginatedSelects(paginatedSelects);
		}
		if (form.invalid) {
		  form.markAllAsTouched();
		  return false;
		}
	
		form.markAsUntouched();
		form.updateValueAndValidity();
		return true;
	  }

	  payComplete(info){
		console.log(info)
	  }

	  
	  
	save() {
		let _this = this;
		_this.isLoading = true;
		this.checking();
		this.PaymentType();
		if (this.ctype == 'motorbike' && this.checkbox == false) {
			console.log('test1');
			_this.utils.errorMessage(' Checkbox is not ticked');

		} else {
			var payment = _this.form.getRawValue()
			
			//Utils.forceTouchPaginatedSelects(_this.paginatedSelects);

			_this.isLoading = false;

			let eventEmitter: EventEmitter<any> = new EventEmitter<any>();

			console.log(payment);
			Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
			if (!DetailsComponent.checkFormValidity(this.form, _this.paginatedSelects)) {
				setTimeout(() => {
				  eventEmitter.emit(false);
				  console.log("Form Invalid => ", this.form);
				}, 500);
				return eventEmitter;
			  }
		  


			_this.orderservice.createPublic(payment).subscribe(res => {
				console.log(res);
				_this.clientsecret = res['data']['client_secret'];
				localStorage.setItem('refNumber', res['data']['order_number'],);
				console.log(localStorage.getItem('refNumber'));
				console.log(_this.clientsecret);
				this.paymentclicked = true;
				//this.router.navigate(['/pender/accepted']);
				
			});
			
		

		}
		
	}


	invokeStripe() {
		if (!window.document.getElementById('stripe-script')) {
			const script = window.document.createElement('script');
			script.id = 'stripe-script';
			script.type = 'text/javascript';
			script.src = 'https://checkout.stripe.com/checkout.js';
			script.onload = () => {
				this.paymentHandler = (<any>window).StripeCheckout.configure({
					key: 'pk_test_51Kpu3KAuEKeMv3jzLh8gXPOedgWwXn6nxyROWNXGmLYX5rg4i9LVpXGPclXfa8ArQwkB186nZM3qhKFMN9SxviHW001dbPO7n5',
					locale: 'auto',
					token: function (stripeToken: any) {
						console.log(stripeToken);
					},
				});
			};

			window.document.body.appendChild(script);
		}
	}

}