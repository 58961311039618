import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/user`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/user/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/user/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/user`,formData);
    }

	delete(id){
		return this.http.delete(`${environment.baseUrl}/user/`+id);
	  }

    updateProfile(formData){
      return this.http.put(`${environment.baseUrl}/user/profile`,formData);
    }

	order(order_direction, sort){
		return this.http.get(`${environment.baseUrl}/user?order_asc_desc=` + order_direction+`&order_by=` + sort);
	}

	page(page){
		return this.http.get(`${environment.baseUrl}/user?page=` +page);
	  }

}
