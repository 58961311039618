import { I } from "@angular/cdk/keycodes";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { MembershipsModel } from "../../../shared/models/memberships.model";
import { MembershipsService } from "../../../shared/services/memberships.service";
import { MembershipsBuildData } from "../memberships.build-data";

interface Itable {
	id: string;
	first_name: string;
	username: string;
}

@Component({
	selector: 'app-memberships-list',
	templateUrl: './memberships-list.component.html',
	styleUrls: ['./memberships-list.component.scss']
})

export class MembershipsComponent implements OnInit {

	test = [];
	name = [];
	sort = [];
	type = [];
	ColumnMode = ColumnMode;
	mem: MembershipsModel[] = [];
	_mem: MembershipsModel[] = [];
	page: MembershipsModel[] = [];
	direction = 'asc' ? 'desc' : 'asc';
	form: FormGroup;
	sorting = [];
	ngOnInit() { 
		this.Status();
		this.setPage({ offset: 0 });
	}

	columnsDynamic = [
		{
			prop: 'order_name',
			flexGrow: 1,
			sortable: true
		},
		{
			prop: 'first_name',
			flexGrow: 3,
			sortable: true
		}, 
		{
			prop: 'product.title',
			flexGrow: 3,
			sortable: true
		},
		{
			prop: 'order_status',
			flexGrow: 3,
			sortable: true
		},
		{
			prop: 'payment_status',
			flexGrow: 3,
			sortable: true
		}
	]
	rowCount = 0;
	curPage = 0;
	pageSize = 25;
	constructor(private router: Router,
		public memService: MembershipsService) {
		var _this = this;
		//this.setPage();
		this.memService.getType(1).subscribe(res => {

			for (var uObject of res['data']) {
				_this.mem.push(new MembershipsModel(uObject));
				this.test = this.mem;
				this.type = this.mem
				//this.name= res['data']['product']['title']
			}
			_this.rowCount = res['meta']['total'];
			_this.curPage = 0;
			console.log(this.test);

		});
	}


	data = new MembershipsBuildData();
	//module = this.data.module; 
	//activeRows: Map<number, any> = new Map<number, any>();
	//@Input() rowFunction;

	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	ll = []
	unique: any
	Status(){
		var _this = this;
		this.memService.getType(1).subscribe(res => {

			for (var uObject of res['data']) {
				_this.ll = uObject['order_status'];
				//document.getElementById('orderStatus').setAttribute('style', 'color: red');
				//_this.unique = this.ll.filter((v, i, a) => a.indexOf(v) === i); 
				console.log(this.ll);
			}
		});
	}

	
	// Status(){
	// 	console.log(document.getElementsByName('orderStatus'));
	// 	// if((<HTMLInputElement>document.getElementsByName('orderStatus')[0]).value == 'active'){
	// 	// 	//document.getElementById('check').setAttribute('style', 'opacity: 1');
	// 	// 	//(<HTMLInputElement>document.getElementsByName("orderStatus")[0]).value
	// 	// 	console.log('lol');
	// 	// }
	// }

	onSort(event) {
		let _this = this;
		this.type = [];

		
		console.log('Sort Event', event);
		_this.loading.isLoading = true;
		// this.memService.getType(1).subscribe(res => {

		// 	for (var uObject of res['data']) {
		// 		_this.mem.push(new MembershipsModel(uObject));
		// 		this.type = this.mem;
		// 		//this.name= res['data']['product']['title']
		// 	}
			
		// });
		
		if (event.sorts['0']['prop'] == 'first_name') {
			setTimeout(() => {

				this.memService.orderMemebership(event.newValue, 'name').subscribe(res => {
					for (var uObject of res['data']) {

						this.type.push(new MembershipsModel(uObject));
						this.sort = this.type;
						this.test = this.sort;

					}
				});

			}, 1000);
		}
		else if (event.sorts['0']['prop'] == 'product.title') {
			setTimeout(() => {

				this.memService.orderMemebership(event.newValue, 'product').subscribe(res => {
					for (var uObject of res['data']) {

						_this.type.push(new MembershipsModel(uObject));
						this.sort = this.type;
						this.test = this.sort;

					}
				});

			}, 1000);
		} else {
			setTimeout(() => {

				this.memService.orderMemebership(event.newValue, event.sorts['0']['prop']).subscribe(res => {
					for (var uObject of res['data']) {

						_this.type.push(new MembershipsModel(uObject));
						this.sort = this.type;
						this.test = this.sort;

					}
				});

			}, 1000);
		}
	
	}

	
	
	
	  setPage (event): void {
		let _this = this;
		this.test = [];
		this.page = [];
		console.log(event['page']); 
		//this.page = 'https://api.pendercarpark.codeblack.mt/api/v1/order?page=' + event['page'];
		this.curPage =event['page'] -1;
		this.memService.pageMembership(event['page']).subscribe(res => {
			
			for (var uObject of res['data']) {
				
				_this.page.push(new MembershipsModel(uObject));
				_this.test = _this.page;
			}
		});

		console.log(this.test);
		
	  }

	newMembership() {
		this.router.navigate(['/pender/memberships-detail/new']);
	}

	onActivate(event) {
		if (event.type == 'click') {
			this.router.navigate(['/pender/memberships-detail/' + event.row.id]);
			//UserDetailComponent
		}
	}


	tablestyle = 'bootstrap';

}
