import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
	constructor(
		private router: Router
	  ) {}
	
	  ngOnInit() {}
	  paymenttype = "";
	  OnlinePayment() {
		localStorage.setItem('paymenttype', 'card');
		this.router.navigate(['/pender/details']);
	  }
	
	  Invoice() {
		localStorage.setItem('paymenttype', 'invoice');
		this.router.navigate(['/pender/details']);
	  }
	  
	}
	