import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from '../../@pages/components/message/message.service';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { LocalStoreService } from '../../shared/services/local-store.service';
import { Location } from '@angular/common';
import { Utils } from '../../shared/services/utils.service';
import { UsersModel } from '../../shared/models/users.model';
import { ProfileService } from '../../shared/services/profile.service';
import { PaginatedSelectComponent } from '../../@pages/components/paginated-select/paginated-select.component';
import { UploadService } from '../../shared/services/upload.service';
import { UserBuildData } from '../users/users.build-data';
import { UsersService } from '../../shared/services/users.service';
import { RolesModel } from '../../shared/models/roles.model';
import { RolesService } from '../../shared/services/roles.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
	
  @ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
  @Input() external: boolean = false;
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  user: UsersModel;
  form: FormGroup;
  errors: String[] = [];
  cardImageBase64: string;
  isImageSaved: boolean;
  roles: any[];
  data = new UserBuildData();
  module = this.data.module;
  constructor(
    private service: ProfileService,
	private userservice: UsersService,
	private snapshot: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
	private rolesService : RolesService,
    private messageService: MessageService,
    private errorHandler: ErrorHandlingService,
    private utils : Utils,
	private uploadService: UploadService,
    private store: LocalStoreService,
    private _location: Location
  ) { }

  ngOnInit() {
	let _this = this;
    this.createForm({});
	this.getRoles();
	this.router.events.subscribe(event => {
		if (event instanceof NavigationStart) {
			this.messageService.remove();
		}
	});
	this.loadData(this.authService.getUserId());
  }

  sendBack() {
    this._location.back();
  }

  markTouched() {
    this.form.markAsTouched();
  }

  createForm(input, form?) {
    input = input || {};
    form ? form : this.form = UsersModel.getFormGroup(input); 
  }

  triggerFetch:EventEmitter<any> = new EventEmitter<any>();
  
  loading = {
	isFirstLoad: false,
	isLoading: false
};

  loadData(id) {
	let _this = this;
	let checkLoadingSpinner = () => {
		if (!_this.loading.isFirstLoad) {
			_this.loading.isFirstLoad = true;
			_this.loading.isLoading = true;
		}
		// _this.triggerFetch.emit(_this.form);
		// _this.loading.isLoading = false;
	}

	checkLoadingSpinner();


	this.service.get(id).subscribe(res => {
		_this.user = res['data'];
		console.log(_this.user);
		_this.createForm(_this.user);
		_this.form.get('username').setValue(res['data']['email']);
		_this.triggerFetch.emit(_this.form);
		_this.loading.isLoading = false;
	},

		err => {
			_this.loading.isLoading = false;
			console.log(err + " HELLO");
		});
}

  isLoading:boolean = false;

//   Passwords() {
// 	let _this = this;
// 		if (_this.form.get('password').value.length < 6) {
// 			_this.utils.errorMessage(' password needs to be longer than 6 characters');
// 		}
// }
isTouched(controlName) {
	if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
	  return true;
	}
	else {
	  return false;
	}
  }

  _roles: RolesModel[] =[];
	getRoles() {
	var _this = this;
	this.rolesService.getAll({}).subscribe(res => {
		for (var uObject of res['data']) {
			
			_this._roles.push(new RolesModel(uObject));
		}
	});
}

  save() {
	let _this = this;
	console.log('tl')

	_this.isLoading = true;
	
	
		_this.form.get('username').setValue(_this.form.get('email').value);
		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.user, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			console.log('tl');
			_this.form.get('username').setValue(res['data']['email']);
			if (res['data']) {
				console.log('ts');
				if (_this.external == true) {

					_this.form.get('profile_picture').setValue(res['data']['file_path']);
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/pender/profile']);
				}

				if (this.form.get('password').value == this.form.get('password_confirmation').value) {
					console.log('matched');
				//	this.Passwords();
					_this.utils.successMessage(_this.module.name + ' saved.');
				} else {
					console.log('no match');
				//	this.Passwords();
					_this.utils.errorMessage(_this.module.name + ' password did not match');
				}

			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})

	
}

  image: [] = [];

  //Photo Upload
  photoChangeEvent(fileInput: any) {
    let _this = this;
    Utils.handleFileChange(fileInput).subscribe(res => {
      let success = res.success;
      if (success) {
		
        _this.cardImageBase64 = res.cardImageBase64;
        _this.isImageSaved = res.isImageSaved;
       // _this.form.get('profile_picture').setValue(res.cardImageBase64);
		var fileName =  _this.cardImageBase64;
		
		var fileExtension = fileName.split('data:image/').pop().split(';base64,/'); 
		var fileType = fileName.split('data:').pop().split(';base64,/'); 
		console.log(fileExtension[0]);
		console.log(fileType[0]);
		// var image = [_this.cardImageBase64, fileType[0], fileExtension[0], 'test'];
		 var jsonObj  = {
			 "data": _this.cardImageBase64,
			 "file_type": fileType[0],
    		"file_ext": fileExtension[0],
    		"description": "file description"
		};
		//var myJSON = JSON.stringify(jsonObj);
		_this.uploadService.create(jsonObj).subscribe(res => {
			_this.user['profile_picture'] = res['data']['file_path'];
			_this.form.get('profile_picture').setValue(res['data']['file_path']);
			console.log();
		});
	
		//console.log(jsonObj);
		//console.log(_this.uploadService.create(jsonObj));
		//_this.saveEvent.emit(res['profile_picture']);
		
      }
      else {
        console.log("Failed to load file.");
      }
    });
  }


}