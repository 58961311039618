import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { DashboardComponent } from './dashboard.component';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { RouterModule } from '@angular/router';
import { SimplyWhiteDashboardRoutes } from './dashboard.routing';


@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    ButtonLoadingModule,
	RouterModule.forChild(SimplyWhiteDashboardRoutes),
    ProgressModule
  ],
  declarations: [DashboardComponent],
  providers:[MessageService]
})
export class DashboardModule {
  
}
