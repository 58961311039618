import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
// import { ButtonLoadingModule } from '../components/btn-loading/btn-loading.module';
// import { PhoneInputModule } from '../components/phone-input/phone-input.module';
import { MembershipsRoutes } from './memberships.routing';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { MembershipsComponent } from './memberships-list/memberships-list.component';
import { MembershipDetailComponent } from './memberships-detail/memberships-detail.component';
import { MembershipDetailNewComponent } from './memberships-detail-new/memberships-detail-new.component';
import { MatDatepickerModule, MatInputModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
	NgxDatatableModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
   //PhoneInputModule,
    TextMaskModule,
    pgSwitchModule,
	MatDatepickerModule,
	MatInputModule,
    ButtonLoadingModule,
    RouterModule.forChild(MembershipsRoutes),
  ],
  declarations: [MembershipsComponent, MembershipDetailComponent, MembershipDetailNewComponent],
  providers:[MessageService]
})
export class MembershipsModule {
  
}
