import { Injectable } from '@angular/core';
import { MenuNavService } from '../services/menu-navigation.service';


@Injectable()
export class RoleService {
  private roles: any = {
    1: {
      role: 'Administrator',
      defaultRoute: '/pender/dashboard'
    },
    2: {
      role: 'Security',
      defaultRoute: '/pender/clients'
    },
   
  };

  constructor(
    private menuService : MenuNavService
  ) { }

  getDefaultRoute(role: number): string{
    // //return "/user";
    // let route = this.menuService.menuItems[0].routerLink ? this.menuService.menuItems[0].routerLink : this.menuService.menuItems[0].submenu[0].routerLink;
    // return route;
    // //return this.roles[role].defaultRoute;
    return "/session/login";
  }
}
