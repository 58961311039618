import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { Location } from '@angular/common';
import { UploadService } from '../../../shared/services/upload.service';
import { MembershipsModel } from '../../../shared/models/memberships.model';
import { MembershipsBuildData } from '../memberships.build-data';
import { MembershipsService } from '../../../shared/services/memberships.service';
import { ClientsModel } from '../../../shared/models/clients.model';
import { ClientsService } from '../../../shared/services/clients.service';
import { OrderModel } from '../../../shared/models/order.model';

@Component({
	selector: 'app-memberships-detail',
	templateUrl: './memberships-detail.component.html',
	styleUrls: ['./memberships-detail.component.scss']
})
export class MembershipDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	mem: MembershipsModel;
	_mem: MembershipsModel[] = [];
	_client: ClientsModel[] = [];
	_status: MembershipsModel[] = [];
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('sendForgotModal', { static: true }) forgotModal: ModalDirective;
	errors: String[] = [];
	data = new MembershipsBuildData();
	module = this.data.module;
	form: FormGroup;
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	id = ''
	constructor(
		private service: MembershipsService,
		public permissionGroupService: PermissionGroupService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private uploadService: UploadService,
		private clientService: ClientsService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		private store: LocalStoreService,
		private _location: Location
	) { }



	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.getStatus();
		this.AllCar();

		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			console.log(res);
			this.id = res['params']['id'];
			this.loadData(res['params']['id']);

		});
	}

	getStatus() {
		let _this = this;
		this.service.status();
	}


	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	getMembershipEmail() {
		let email = this.form.get('email').value;
		return email;
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}



	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
	@Input() prefix: string;
	@Input() control: string;
	value: any;


	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	vehicles: FormArray;
	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = MembershipsModel.getFormGroup(input);
		if (!form) {
			this.vehicles = this.form.get('vehicles') as FormArray;
		}
	}

	selectedVehicleID = null;
	AddNewVehicle() {
		var _this = this;
		_this.vehicles.push(OrderModel.getVehiclesFormGroup({
			vehicle: this.selectedVehicleID,
			make: '',
			reg_no: '',
			//_vehicle : this.getVehiclekByID(this.selectedVehicleID)
		}));
	}

	RemoveVehicle(idx) {
		var _this = this;
		//array.splice(_this.vehicles.length-1, 0, 0);
		_this.vehicles.removeAt(idx);
		if (idx == 0) {
			this.AddNewVehicle();
		}
	}

	Back() {
		this._location.back();
	}

	newMembership() {
		this.router.navigate(['/pender/memberships-detail/new']);
	}

	openFileUpload(ele) {
		$(ele).click();
	}


	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	validto
	valiffrom
	email
	prod
	orderfirstname
	orderlastname
	cars
	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
			// _this.triggerFetch.emit(_this.form);
			// _this.loading.isLoading = false;
		}

		checkLoadingSpinner();


		this.service.get(id).subscribe(res => {
			_this.mem = res['data'];
			console.log(_this.mem);
			_this.createForm(_this.mem);
			this.prod = res['data']['product']['id']
			this.email = res['data']['customer']['email']
			this.orderfirstname = res['data']['customer']['first_name']
			this.orderlastname = res['data']['customer']['last_name']
	
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	// changeMake(index, event){
	// 	let _this = this;
	// 	this.service.get(this.mem.id).subscribe(res => {
	// 	_this.form.get('vehicles')['controls'].at(index).get('make').setValue(res['data']['vehicles']['make']);
	// 	});
	// }

	_car = []
	AllCar() {
		var _this = this;
		this.service.car().subscribe(res => {
			_this._car = res['data']['makes'];
		});
	}


	loadCustomer() {
		var _this = this;
		this.clientService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._client.push(new ClientsModel(uObject));
			}
		});
	}

	ctype = ''
	status(event: any) {
		this.ctype = event.target.value;
		//this._mem = [];

		if (this.ctype == 'active') {
			console.log('it is active');
			this.service.activate(this.form, {}).subscribe(res => {
				for (var uObject of res['data']) {
					console.log(uObject);
				}
			});
		}

	}


	@Input() isLoading: boolean = false;
	save() {
		let _this = this;
		_this.isLoading = true;

		_this.form.get("product").setValue(this.prod);
		_this.form.get("email").setValue(this.email);
		_this.form.get("order_first_name").setValue(this.orderfirstname);
		_this.form.get("order_last_name").setValue(this.orderlastname);

		if (_this.form.get("membership_no").value === null) {
			_this.utils.errorMessage('Card Number can not be empty');
		}
		else {


			Utils.forceTouchPaginatedSelects(_this.paginatedSelects);

			Utils.handleFormSave(this.form, this.service, this.mem, this.module.name, this.createForm, null).subscribe(res => {
				_this.isLoading = false;

				if (res['data']) {
					console.log('here')
					if (_this.external == true) {
						_this.saveEvent.emit(res['data']);

					}
					else {
						this.router.navigate(['/pender/memberships']);
					}
					_this.utils.successMessage(_this.module.name + ' saved.');
				}
				else {
					Utils.sendFormSaveError(res, this.module, this.utils);
				}
			})
		}
	}


}