import { FormBuilder, FormGroup } from "@angular/forms";

export class RolesModel {
	id: string;
    title: string;

 
    constructor(input?) {
      input = input || {};
	  this.id = input.id || '';
	  this.title = input.title || '';

    }

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			id: [input.id],
			title: [input.title],

		})
	}
  }
  