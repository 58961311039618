import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

export class MembershipsModel {

	id: string;
	membership_no: string;
	payment_type: string;
	payment_status: string;
	order_status: string;
	stripe_reference: string;
	order_number: string;
	sub_total: number;
	vat_total: number;
	total_price: number;
	valid_from: string;
	valid_to: string;
	order_first_name: string;
	order_last_name: string;
	vehicles: any[];

	title: string;
	price: string;
	is_membership: string;
	vehicle_type: string;
	membership_months: string;

	address_1: string;
	address_2: string;
	product: number;
	email: string;
	first_name: string;
	last_name: string;
	post_code: string;
	phone_mobile: number;
	phone_number: number;
	id_card: string;
	company_name:string;


	constructor(input?) {
		input = input || {};
		
		this.id = input.id || '';
		this.membership_no = input.membership_no || '';
		this.payment_type = input.payment_type || '';
		this.payment_status = input.payment_status || '';
		this.stripe_reference = input.stripe_reference || '';
		this.sub_total = input.sub_total || '';
		this.vat_total = input.vat_total || '';
		this.total_price = input.total_price || '';
		this.valid_from = input.valid_from || '';
		this.valid_to = input.valid_to || '';
		this.order_number = input.order_number || '';
		this.order_first_name = input.order_first_name || '';
		this.order_last_name = input.order_last_name || '';
		this.order_status = input.order_status || '';

		this.title = input.title || '';
		this.price = input.price || '';
		this.is_membership = input.is_membership || '';
		this.vehicle_type = input.vehicle_type || '';
		this.membership_months = input.membership_months || '';

		this.product = input.product || '';
		this.first_name = input.first_name || '';
		this.last_name = input.last_name || '';
		this.email = input.email || '';
		this.post_code = input.post_code || '';
		this.address_2 = input.address_2 || '';
		this.address_1 = input.address_1 || '';
		this.phone_mobile = input.phone_mobile || '';
		this.phone_number = input.phone_number || '';
		this.id_card = input.id_card || '';
		this.company_name = input.company_name || '';
	}

	static getVehiclesFormGroup(vehicles?) {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			make: new FormControl(vehicles ? vehicles.make : 1, Validators.required),
			reg_no: new FormControl(vehicles ? vehicles.reg_no : '', Validators.required),
		});
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();



		let VehiclesFormArray = new FormArray([]);
		if (input.vehicles) {
			for (let vehicle of input.vehicles) {
				VehiclesFormArray.push(this.getVehiclesFormGroup(vehicle));
			}
		}

		return fb.group({
			membership_no: [input.membership_no, Validators.required],
			order_first_name: [input.order_first_name, Validators.required],
			payment_type: [input.payment_type, Validators.required],
			payment_status: [input.payment_status],
			stripe_reference: [input.stripe_reference],
			sub_total: [input.sub_total],
			vat_total: [input.vat_total],
			total_price: [input.total_price],
			valid_from: [input.valid_from],
			valid_to: [input.valid_to],
			order_last_name: [input.order_last_name, Validators.required],
			order_number:[input.order_number],
			order_status: [input.order_status],


			price: [input.price],
			title: [input.title],
			is_membership: [input.is_membership],
			vehicle_type: [input.vehicle_type],
			membership_months: [input.membership_months],


			product: [input.price, Validators.required],
			first_name: [input.first_name, Validators.required],
			last_name: [input.last_name, Validators.required],
			email: [input.email, Validators.required],
			address_1: [input.address_1],
			address_2: [input.address_2],
			post_code: [input.post_code],
			phone_number: [input.phone_number],
			phone_mobile: [input.phone_mobile],
			id_card: [input.id_card],
			company_name: [input.company_name],
			// product: ProductFormArray,
			// customer: CustomerFormArray,
			vehicles: VehiclesFormArray
		})
	}

}