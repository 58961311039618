import { Component, EventEmitter, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ReportModel } from '../../shared/models/report.model';
import { SimplyWhiteDashboardBuildData } from './dashboard.build-data';
import { ColumnMode } from "@swimlane/ngx-datatable";
import { FormArray, FormGroup } from '@angular/forms';
import { ReportService } from '../../shared/services/report.service';
import { MessageService } from '../../@pages/components/message/message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	date = new Date();
	startdate = this.date.setDate(this.date.getDate() - 1);
	start = formatDate(this.startdate, 'yyyy-MM-dd', 'en');
	enddate = this.date.setDate(this.date.getDate() +1);
	end = formatDate(this.enddate, 'yyyy-MM-dd', 'en');
	calstart = formatDate(this.startdate, 'yyyy-MM-ddT00:00', 'en');
	calend = formatDate(this.enddate, 'yyyy-MM-ddT23:59', 'en');

	test = [];
	//user: UsersModel[] = [];
	form: FormGroup;
	_rep : ReportModel[] = [];
	vf = '';
	vt = '';
	ngOnInit(){
		this.createForm({});
		this.CalenderDate();
		this.getMProducts();
		this.getVProducts();
		this.getMVehicles();
		//console.log(this.calstart);
		//console.log(this.start);
		//this.vf = this.start;
		//this.vt = this.end;
		// this.getIProducts();
		// this.router.events.subscribe(event => {
		// 	if (event instanceof NavigationStart) {
		// 		this.messageService.remove();
		// 	}
		// });
	}

	constructor(private router: Router, private services: ReportService, private messageService: MessageService, ){}

	getDates(){
		this.vf = ((<HTMLInputElement>document.getElementById("validfrom")).value) + ':00Z';
		this.vt = ((<HTMLInputElement>document.getElementById("validto")).value) + ':00Z';
		console.log(this.vf);
		console.log(this.vt);
		this.services.getMembership(this.vf, this.vt).subscribe(res => {
			console.log(res['data']);

		});
		
		this.getIProducts();
	}

	CalenderDate(){
		this.vf = this.calstart+ ':00Z';
		this.vt = this.calend+ ':00Z';
		console.log('this.vt')
		this.services.getMembership(this.vf, this.vt).subscribe(res => {
			console.log(res['data']);
			
		});
		this.getIProducts();
		
	}
	
	data = new SimplyWhiteDashboardBuildData();
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}
	mprod= []
	Mcount = ''
	getMProducts(){
		console.log(this._rep);
		this.services.getMembership(this.vf, this.vt).subscribe(res => {
			console.log(res['data']['memberships']);
			
				this.mprod =  res['data']['memberships']['products'];
				this.Mcount =  res['data']['memberships']['membership_count'];
				console.log(this.mprod);

		});
	}

	vprod= []
	Vcount = ''
	getVProducts(){
		console.log(this._rep);
		this.services.getMembership(this.vf, this.vt).subscribe(res => {
				this.vprod =  res['data']['vouchers']['products'];
				this.Vcount =  res['data']['vouchers']['membership_count'];
				console.log(this.vprod);
				//console.log(this.Vcount);
		});
	}

	mveh= []
	getMVehicles(){
		console.log(this._rep);
		this.services.getMembership(this.vf, this.vt).subscribe(res => {
				this.mveh =  res['data']['memberships']['vehicles'];
				console.log(this.mveh);

		});
	}

	iprod= []
	Itotal = ''
	getIProducts(){
		console.log(this._rep);
		this.services.getIncome(this.vf, this.vt).subscribe(res => {
				this.iprod =  res['data']['products'];
				this.Itotal =  res['data']['total'];
				console.log(this.iprod);

		});
	}

	memberships: FormArray;
	vouchers: FormArray;
	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = ReportModel.getFormGroup(input);
		this.memberships = this.form.get('memberships') as FormArray;
		this.vouchers = this.form.get('vouchers') as FormArray;
	}


	tablestyle = 'bootstrap';
}
