import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
//import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';
import { ClientsService } from "../../../shared/services/clients.service";
import { ClientsModel } from "../../../shared/models/clients.model";
import { Router } from "@angular/router";
import { ClientsBuildData } from "../clients.build-data";
import { ColumnMode } from "@swimlane/ngx-datatable";

// interface Itable{
// 	id: string;
// 	first_name: string;
// 	username: string;
// }
@Component({
	selector: 'app-clients-list',
	templateUrl: './clients-list.component.html',
	styleUrls: ['./clients-list.component.scss']
  })

export class ClientsComponent implements OnInit {

	
	test = [];
	ColumnMode = ColumnMode;
	client: ClientsModel[] = [];
	sort=[];
	
	ngOnInit(){}

	columnsDynamic = [
		{
			name : 'reference_no',
			flexGrow: 1,
			sortable: true
		},
		{
			prop : 'first_name',
			flexGrow: 2,
			sortable: true
		},
		{
			prop : 'last_name',
			flexGrow: 2,
			sortable: true
		},
		{
			name : 'email',
			flexGrow: 3,
			sortable: true
		}
	]

	rowCount = 0;
	curPage = 0;
	pageSize = 25;

	constructor(private router: Router, 
		public clientService: ClientsService){
			var _this = this;
			this.clientService.getAll({}).subscribe(res => {
				for (var uObject of res['data']) {
					_this.client.push(new ClientsModel(uObject));
					this.test = this.client;
				}
				_this.rowCount = res['meta']['total'];
			_this.curPage = 0;
			console.log(this.test);
			});
		
	}

	
	data = new ClientsBuildData();
  	//module = this.data.module; 
	//activeRows: Map<number, any> = new Map<number, any>();
	//@Input() rowFunction;
	
	loading = {
		isFirstLoad: false,
		isLoading: false
	};

	onSort(event) {
		let _this = this;
		this.client = [];
		console.log('Sort Event', event);
		_this.loading.isLoading = true;
		if (event.sorts['0']['prop'] == 'first_name') {
			setTimeout(() => {

				this.clientService.order(event.newValue, 'name').subscribe(res => {
					for (var uObject of res['data']) {

						_this.client.push(new ClientsModel(uObject));
						this.sort = this.client;
						this.test = this.sort;

					}
				});

			}, 1000);
		} else {
			setTimeout(() => {

				this.clientService.order(event.newValue, event.sorts['0']['prop']).subscribe(res => {
					for (var uObject of res['data']) {

						_this.client.push(new ClientsModel(uObject));
						this.sort = this.client;
						this.test = this.sort;

					}
				});

			}, 1000);
		}
	}

	page: ClientsModel[] = [];
	setPage (event): void {
		let _this = this;
		this.test = [];
		this.page = [];
		console.log(event); 
		
		this.curPage =event['page'] -1;
		this.clientService.page(event['page']).subscribe(res => {
			
			for (var uObject of res['data']) {
				
				_this.page.push(new ClientsModel(uObject));
				_this.test = _this.page;
				//console.log(this.test);
			}
		});

		
		
	  }

	newClient(){
    	this.router.navigate(['/pender/clients-detail/new']);
	}

	onActivate(event) {
		if(event.type == 'click') {
			this.router.navigate(['/pender/clients-detail/' +event.row.id]);
			//UserDetailComponent
		}
	}


	tablestyle = 'bootstrap';

}

