import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { VoucherDetailComponent } from './vouchers-detail/vouchers-detail.component';
import { VoucherComponent } from './vouchers-list/vouchers-list.component';
import { VoucherDetailNewComponent } from './vouchers-detail-new/vouchers-detail-new.component';

@NgModule({
  imports: [
    CommonModule,
	NgxDatatableModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    ButtonLoadingModule,
  ],
  declarations: [VoucherComponent, VoucherDetailComponent, VoucherDetailNewComponent],
  providers:[MessageService]
})
export class VouchersModule {
  
}
