import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-declined',
  templateUrl: './declined.component.html',
  styleUrls: ['./declined.component.scss']
})
export class DeclinedComponent implements OnInit {
	constructor(
		private router: Router
	  ) {}
	
	  ngOnInit() {}
	 
	
	  
	  
	}
	