import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { RenewalComponent } from './renewal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
	ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
	//NgxStripeModule.forRoot('pk_test_51I69xgJ8aQygRtovt6v3WzpsJFT9vCzWN18v6kmzxlCUgGs7fCkh0N8SRF2mhpYyS1bbqlBV0yrI3J3LWkvhqfTr00x5b4J9gd'),
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    pgDatePickerModule,
    ButtonLoadingModule,
    ProgressModule
  ],
  declarations: [RenewalComponent],
  providers:[MessageService]
})
export class RenewalModule {
  
}
