import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { UsersModel } from '../../../shared/models/users.model';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { UsersService } from '../../../shared/services/users.service';
import { UserBuildData } from '../users.build-data';
import { Location } from '@angular/common';
import { UploadService } from '../../../shared/services/upload.service';
import { HttpClient } from '@angular/common/http';
import { RolesService } from '../../../shared/services/roles.service';
import { RolesModel } from '../../../shared/models/roles.model';

@Component({
	selector: 'app-users-detail',
	templateUrl: './users-detail.component.html',
	styleUrls: ['./users-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	user: UsersModel;

	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('sendForgotModal', { static: true }) forgotModal: ModalDirective;
	errors: String[] = [];
	data = new UserBuildData();
	module = this.data.module;
	form: FormGroup;
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

	constructor(
		private service: UsersService,
		public permissionGroupService: PermissionGroupService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private uploadService: UploadService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private rolesService : RolesService,
		private http: HttpClient,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		private store: LocalStoreService,
		private _location: Location
	) { }



	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.getRoles();
		//this.validatePassword();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadData(res['params']['id']);
			}
		});
	}



	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}


	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
	@Input() prefix: string;
	@Input() control: string;
	value: any;

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}

	_roles: RolesModel[] =[];
	getRoles() {
	var _this = this;
	this.rolesService.getAll({}).subscribe(res => {
		for (var uObject of res['data']) {
			
			_this._roles.push(new RolesModel(uObject));
		}
	});
}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = UsersModel.getFormGroup(input);
	}

	Back() {
		this._location.back();
	}

	newUser() {
		this.router.navigate(['/pender/users-detail/new']);
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	isTouched(controlName) {
		if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
		  return true;
		}
		else {
		  return false;
		}
	  }

	loading = {
		isFirstLoad: false,
		isLoading: false
	};


	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
			// _this.triggerFetch.emit(_this.form);
			// _this.loading.isLoading = false;
		}

		checkLoadingSpinner();


		this.service.get(id).subscribe(res => {
			_this.user = res['data'];
			console.log(_this.user);
			_this.createForm(_this.user);
			_this.form.get('username').setValue(res['data']['email']);
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},

			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}


	delete() {
		let _this = this;
		_this.isLoading = true;
		this.utils.delete(this.service, this.user.id, this.module.name, "/pender/users").subscribe(res => {
			_this.isLoading = false;
		});
	}

	Passwords() {
		let _this = this;
		if (_this.form.get('password').value == null) {
			console.log('');
		} else if (_this.form.get('password').value.length < 6) {
			_this.utils.errorMessage(_this.module.name + ' password needs to be longer than 6 characters');
		}


	}


	@Input() isLoading: boolean = false;

	save() {
		let _this = this;
		_this.isLoading = true;
		console.log('tl');

		_this.form.get('username').setValue(_this.form.get('email').value);
		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.user, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;

			_this.form.get('username').setValue(res['data']['email']);
			if (res['data']) {
				if (_this.external == true) {

					_this.form.get('profile_picture').setValue(res['data']['file_path']);
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/pender/users']);
				}

				if (this.form.get('password').value == this.form.get('password_confirmation').value) {
					console.log('matched');
				//	this.Passwords();
					_this.utils.successMessage(_this.module.name + ' saved.');
				} else {
					console.log('no match');
				//	this.Passwords();
					_this.utils.errorMessage(_this.module.name + ' password did not match');
				}

			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})

	}



	//Photo Upload
	photoChangeEvent(fileInput: any) {
		let _this = this;
		Utils.handleFileChange(fileInput).subscribe(res => {
			let success = res.success;
			if (success) {

				_this.cardImageBase64 = res.cardImageBase64;
				_this.isImageSaved = true;
				// _this.form.get('profile_picture').setValue(res.cardImageBase64);
				var fileName = _this.cardImageBase64;

				var fileExtension = fileName.split('data:image/').pop().split(';base64,/');
				var fileType = fileName.split('data:').pop().split(';base64,/');
				console.log(fileExtension[0]);
				console.log(fileType[0]);
				// var image = [_this.cardImageBase64, fileType[0], fileExtension[0], 'test'];
				var jsonObj = {
					"data": _this.cardImageBase64,
					"file_type": fileType[0],
					"file_ext": fileExtension[0],
					"description": "file description"
				};
				//var myJSON = JSON.stringify(jsonObj);
				_this.uploadService.create(jsonObj).subscribe(res => {
					//_this.user['profile_picture'] = res['data']['file_path'];
					_this.form.get('profile_picture').setValue(res['data']['file_path']);
					console.log();
				});

			}
			else {
				console.log("Failed to load file.");
			}
		});
	}

}