import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { ProgressModule } from '../../@pages/components/progress/progress.module';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { DetailsComponent } from './details.component';
import { NgxStripeModule } from 'ngx-stripe';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { StripeModule } from 'stripe-angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
	ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
	NgxStripeModule.forRoot('pk_test_51Kpu3KAuEKeMv3jzLh8gXPOedgWwXn6nxyROWNXGmLYX5rg4i9LVpXGPclXfa8ArQwkB186nZM3qhKFMN9SxviHW001dbPO7n5'),
    pgSelectModule,
	StripeModule.forRoot('pk_test_51Kpu3KAuEKeMv3jzLh8gXPOedgWwXn6nxyROWNXGmLYX5rg4i9LVpXGPclXfa8ArQwkB186nZM3qhKFMN9SxviHW001dbPO7n5'),
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
	MatDatepickerModule,
	MatNativeDateModule,
	
	MatInputModule,
    pgDatePickerModule,
    ButtonLoadingModule, 
    ProgressModule
  ],
  declarations: [DetailsComponent],
  providers:[MessageService]
})
export class DetailsModule {
  
}
