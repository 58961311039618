import { EventEmitter } from "@angular/core";
import { ClientsModel } from "../../shared/models/clients.model";
import { ColumnModel } from "../../shared/models/column.model";

export class ClientsBuildData{
    module = {
        name: "Clients",
        new_name: "clients",
        has_dot_menu: true,
        icon:'fa-street-view',
        permission:'clients'
    }
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:ClientsModel) => {//Generates Row for codeblack-table
        return ClientsModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            row_accessor: "id",
            toggle: false
        },
        {
            title: "Name",
            prop: "first_name",
            flex_grow: 2,
            row_accessor: "name"
        },
        {
            title: "Email",
            prop: "last_name",
            flex_grow: 2,
            row_accessor: "email"
        },
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}