import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit {
  constructor(
	private router: Router,
	private productservice: ProductService
  ) {}
  ismem = 1;
  //localStorage['ismem'] = 0;
  ngOnInit() {}

  Membership() {
    let _this = this;
	localStorage.setItem('ismem', '1');
	//_this.is_membership = 1;
    this.router.navigate(['/pender/details']);
  }

  Renewal() {
    let _this = this;
	localStorage.setItem('ismem', '0');
	localStorage.ismem = 0;
    this.router.navigate(['/pender/renewal']);
  }
  
}
