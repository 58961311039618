import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PaginatedSelectComponent } from "../../@pages/components/paginated-select/paginated-select.component";
import { ClientsModel } from "../../shared/models/clients.model";
import { OrderModel } from "../../shared/models/order.model";
import { OrderService } from "../../shared/services/order.service";
import { ProductService } from "../../shared/services/product.service";
import { Utils } from "../../shared/services/utils.service";
import { RenewalBuildData } from "./renewal.build-data";

@Component({
	selector: 'app-renewal',
	templateUrl: './renewal.component.html',
	styleUrls: ['./renewal.component.scss']
})
export class RenewalComponent implements OnInit {

	form: FormGroup;
	order: OrderModel;
	client: ClientsModel;
	data = new RenewalBuildData();
  	module = this.data.module;


	constructor(
		private router: Router,
		private http: HttpClient,
		private orderservice: OrderService,
		private utils: Utils,
		private productservice: ProductService) { }

		
	ngOnInit(){
		this.createForm({});
	}

	Back() {
		this.router.navigate(['/pender/type']);
	}

	isTouched(controlName) {
		if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
			return true;
		}
		else {
			return false;
		}
	}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = OrderModel.getFormGroup(input);
	}

	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];
	@Input() external: boolean = false;
	@Input() isLoading: boolean = false;

	// static forceTouchPaginatedSelects(paginated_selects: PaginatedSelectComponent[]) {
	// 	if (paginated_selects) {
	// 	  for (let paginatedSelect of paginated_selects['_results']) {
	// 		let select: PaginatedSelectComponent = paginatedSelect;
	// 		if (select && select.pg_select) {
	// 		  select.form.markAllAsTouched();
	// 		}
	// 	  }
	// 	}
	//   }

	// static checkFormValidity(form: FormGroup, paginatedSelects?: PaginatedSelectComponent[]): boolean {
	// 	form.updateValueAndValidity();
	// 	if (paginatedSelects) {
	// 	  this.forceTouchPaginatedSelects(paginatedSelects);
	// 	}
	// 	if (form.invalid) {
	// 	  form.markAllAsTouched();
	// 	  return false;
	// 	}
	
	// 	form.markAsUntouched();
	// 	form.updateValueAndValidity();
	// 	return true;
	//   }

	memnum = true
	emailbool = true
	onActivate(event) {
		let _this = this;

		// let eventEmitter: EventEmitter<any> = new EventEmitter<any>();
		// if (!RenewalComponent.checkFormValidity(this.form, _this.paginatedSelects)) {
		// 	setTimeout(() => {
		// 	  eventEmitter.emit(false);
		// 	  console.log("Form Invalid => ", this.form);
		// 	}, 500);
		// 	return eventEmitter;
		//   }

		if(_this.form.get('membership_no').value === null){
			console.log('empty');
			this.memnum = false
		}

		if(_this.form.get('email').value === null){
			console.log('empty');
			this.emailbool = false
		}

		if(event.type == 'click') {
			_this.orderservice.renewal(_this.form.get('membership_no').value, _this.form.get('email').value).subscribe(res => {
				_this.triggerFetch.emit(_this.form);
				_this.saveEvent.emit(res['data']);
				console.log(res['data']);
				this.router.navigate(['/pender/details/'], {queryParams: _this.form.getRawValue()});
				

			});
			
			
			
		}
	}

}