import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../@pages/components/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { pgSelectModule} from '../../@pages/components/select/select.module';
import { MessageModule } from '../../@pages/components/message/message.module';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { pgSwitchModule } from '../../@pages/components/switch/switch.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ClientsRoutes } from './clients.routing';
import { ButtonLoadingModule } from '../../@pages/components/btn-loading/btn-loading.module';
import { ClientsComponent } from './clients-list/clients-list.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ClientDetailComponent } from './clients-detail/clients-detail.component';
import { pgDatePickerModule } from '../../@pages/components/datepicker/datepicker.module';
import { ProgressModule } from '../../@pages/components/progress/progress.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule, 
    SharedModule,
    NgxDatatableModule,
    pgSelectModule,
    MessageModule,
    ModalModule,
    TextMaskModule,
    pgSwitchModule,
    pgDatePickerModule,
    ButtonLoadingModule,
    ProgressModule,
    RouterModule.forChild(ClientsRoutes),
  ],
  declarations: [ClientsComponent, ClientDetailComponent],
  providers:[MessageService]
})
export class ClientsModule {
  
}
