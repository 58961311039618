import { Component, OnInit, ViewChild, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import * as $ from 'jquery';
import { PermissionService } from '../../../shared/services/permission.service';
import { PermissionGroupService } from '../../../shared/services/permission-group.service';
import { LocalStoreService } from '../../../shared/services/local-store.service';
import { Utils } from '../../../shared/services/utils.service';
import { PaginatedSelectComponent } from '../../../@pages/components/paginated-select/paginated-select.component';
import { Location } from '@angular/common';
import { UploadService } from '../../../shared/services/upload.service';
import { MembershipsModel } from '../../../shared/models/memberships.model';
import { MembershipsService } from '../../../shared/services/memberships.service';
import { ClientsModel } from '../../../shared/models/clients.model';
import { ClientsService } from '../../../shared/services/clients.service';
import { VoucherBuildData } from '../vouchers.build-data';
import { VoucherModel } from '../../../shared/models/voucher.model';

@Component({
	selector: 'app-vouchers-detail',
	templateUrl: './vouchers-detail.component.html',
	styleUrls: ['./vouchers-detail.component.scss']
})
export class VoucherDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	mem: VoucherModel;
	_client: ClientsModel[] = [];
	_status: MembershipsModel[] = [];
	roles: any[];
	@ViewChild('deleteModal', { static: true }) modalDelete: ModalDirective;
	@ViewChild('sendForgotModal', { static: true }) forgotModal: ModalDirective;
	errors: String[] = [];
	data = new VoucherBuildData();
	module = this.data.module;
	form: FormGroup;
	@ViewChildren(PaginatedSelectComponent) paginatedSelects: PaginatedSelectComponent[];

	constructor(
		private service: MembershipsService,
		public permissionGroupService: PermissionGroupService,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private uploadService: UploadService,
		private clientService: ClientsService,
		private messageService: MessageService,
		private errorHandler: ErrorHandlingService,
		private utils: Utils,
		private cdr: ChangeDetectorRef,
		private store: LocalStoreService,
		private _location: Location
	) { }



	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.getStatus();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			console.log(res);
			// this.loadData(res['params']['id']);
			this.loadData(res['params']['id']);

		})
	}

	getStatus() {
		let _this = this;
		this.service.status();
	}



	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	getMembershipEmail() {
		let email = this.form.get('email').value;
		return email;
	}

	isFormValueChecked(controlName) {
		return this.form.get(controlName).value;
	}



	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;
	@Input() prefix: string;
	@Input() control: string;
	value: any;


	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}


	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = VoucherModel.getFormGroup(input);

	}

	Back() {
		this._location.back();
	}

	newVoucher() {
		this.router.navigate(['/pender/vouchers-detail/new']);
	}

	openFileUpload(ele) {
		$(ele).click();
	}


	loading = {
		isFirstLoad: false,
		isLoading: false
	};


	email
	prod
	loadData(id) {
		let _this = this;
		let checkLoadingSpinner = () => {
			if (!_this.loading.isFirstLoad) {
				_this.loading.isFirstLoad = true;
				_this.loading.isLoading = true;
			}
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		}

		checkLoadingSpinner();


		this.service.get(id).subscribe(res => {
			_this.mem = res['data'];
			console.log(_this.mem);
			_this.createForm(_this.mem);
			this.prod = res['data']['product']['id']
			console.log(this.prod)
			this.email = res['data']['customer']['email']
			_this.triggerFetch.emit(_this.form);
			_this.loading.isLoading = false;
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err + " HELLO");
			});
	}

	//Variable & Function for <phone-input>
	triggerFetch: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
	updateForm(event) {
		this.form = event;
	}

	delete() {
		let _this = this;
		_this.isLoading = true;
		console.log(this.mem.id);
		console.log(this.module.name);
		console.log(this.service);
		this.utils.delete(this.service, this.mem.id, this.module.name, "/pender/memberships").subscribe(res => {
			_this.isLoading = false;
		});
	}

	loadCustomer() {
		var _this = this;
		this.clientService.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._client.push(new ClientsModel(uObject));
			}
		});
	}

	ctype = ''
	status(event: any) {
		this.ctype = event.target.value;
		//this._mem = [];

		if (this.ctype == 'active') {
			console.log('it is active');
			this.service.activate(this.form, {}).subscribe(res => {
				for (var uObject of res['data']) {
					console.log(uObject);
				}
			});
		}

	}


	@Input() isLoading: boolean = false;
	save() {
		let _this = this;
		_this.isLoading = true;

		//   if(this.fromDate){
		// 	_this.form.get("valid_from").setValue(moment(this.fromDate).format());
		//   }

		//   if(this.toDate){
		// 	_this.form.get("valid_to").setValue(moment(this.fromDate).format());
		//   }
			_this.form.get("product").setValue(this.prod);
			_this.form.get("email").setValue(this.email);


		Utils.forceTouchPaginatedSelects(_this.paginatedSelects);
		Utils.handleFormSave(this.form, this.service, this.mem, this.module.name, this.createForm, null).subscribe(res => {
			_this.isLoading = false;
			if (res['data']) {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/pender/vouchers']);
				}
				_this.utils.successMessage(_this.module.name + ' saved.');
			}
			else {
				Utils.sendFormSaveError(res, this.module, this.utils);
			}
		})
	}
}