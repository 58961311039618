import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/customer`,{params:searchParams});
    }

    get(id){
      return this.http.get(`${environment.baseUrl}/customer/`+id);
    }

    edit(id,formData){
      return this.http.put(`${environment.baseUrl}/customer/`+id,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/customer`,formData);
    }

    delete(id){
      return this.http.delete(`${environment.baseUrl}/customer/`+id);
    }

    reference(reference){
      return this.http.get(`${environment.baseUrl}/customer/reference/`+reference);
    }

	order(order_direction, sort){
		return this.http.get(`${environment.baseUrl}/customer?order_asc_desc=` + order_direction+`&order_by=` + sort);
	}

	page(page){
		return this.http.get(`${environment.baseUrl}/customer?page=` +page);
	  }

	  getCompany(formData){
		return this.http.get(`${environment.baseUrl}/customer?is_company=1`, formData);
	  }

}
