// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';


// Layouts
import {
  CondensedComponent,
  BlankComponent,
  RootLayout,
  CorporateLayout,
  SimplyWhiteLayout,
  ExecutiveLayout,
  CasualLayout,
  DemoComponent
} from './@pages/layouts';
// Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';

// Shared Layout Components
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { QuickviewComponent } from './@pages/components/quickview/quickview.component';
import { QuickviewService } from './@pages/components/quickview/quickview.service';
import { SearchOverlayComponent } from './@pages/components/search-overlay/search-overlay.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from './@pages/components/shared.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';

// Basic Bootstrap Modules
import {
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule
} from 'ngx-bootstrap';

// Pages Globaly required Components - Optional
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { ProgressModule } from './@pages/components/progress/progress.module';

// Thirdparty Components / Plugins - Optional
import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import { IsotopeModule } from 'ngx-isotope';
import { StepsformDirective } from './social/stepsform.directive';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// Service - Demo content - Optional
import { ChartService } from './charts/charts.service';
import { SocialService } from './social/social.service';

// Social Page - Optional
import { SocialComponent } from './social/social.component';
import { CoverpageDirective } from './social/coverpage.directive';

// Demo Pages - Optional
import { FormWizardComponent } from './forms/form-wizard/form-wizard.component';
import { CardsComponentPage } from './cards/cards.component';
import { ViewsPageComponent } from './views/views.component';
import { ChartsComponent } from './charts/charts.component';

// Dashboard Widgets - Optional
import { DashboardModule } from './dashboard/simplywhite/dashboard.module';

// Dashboards - Optional
import { CondensedDashboardComponent } from './dashboard/condensed/dashboard.component';
import { CasualDashboardComponent } from './dashboard/casual/dashboard.component';
import { CorporateDashboardComponent } from './dashboard/corporate/dashboard.component';
import { ExecutiveDashboardComponent } from './dashboard/executive/dashboard.component';

// Sample Blank Pages - Optional
import { BlankCorporateComponent } from './@pages/layouts/blank-corporate/blank-corporate.component';
import { BlankSimplywhiteComponent } from './@pages/layouts/blank-simplywhite/blank-simplywhite.component';
import { BlankCasualComponent } from './@pages/layouts/blank-casual/blank-casual.component';
import { ProfileModule } from './dashboard/profile/profile.module';
import { UsersModule } from './dashboard/users/users.module';
import { ClientsModule } from './dashboard/clients/clients.module';
import { MembershipsModule } from './dashboard/memberships/memberships.module';
import { TypeComponent } from './dashboard/type/type.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { CheckoutComponent } from './dashboard/checkout/checkout.component';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';
import { RoleService } from './shared/guards/role.service';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './shared/interceptors/error.interceptor';
import { EncodeHttpParamsInterceptor } from './shared/interceptors/http.interceptor';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DetailsModule } from './dashboard/details/details.module';
import { ResultComponent } from './dashboard/result/result.component';
import { DeclinedComponent } from './dashboard/declined/declined.component';
import { AcceptedComponent } from './dashboard/accepted/accepted.component';
import { RenewalComponent } from './dashboard/renewal/renewal.component';
import { RenewalModule } from './dashboard/renewal/renewal.module';
import { VouchersModule } from './dashboard/vouchers/vouchers.module';
import { AppRoutingModule } from './app-routing.module';
import { SessionModule } from './session/session.module';
import { LoginComponent } from './session/login/login.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Hammer Config Overide
// https://github.com/angular/angular/issues/10541
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CondensedComponent,
    CorporateLayout,
    SimplyWhiteLayout,
    ExecutiveLayout,
    CasualLayout,
    SidebarComponent,
    QuickviewComponent,
    SearchOverlayComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    BlankComponent,
    RootLayout,
    CardsComponentPage,
    ViewsPageComponent,
    ChartsComponent,
    SocialComponent,
    StepsformDirective,
    CoverpageDirective,
    // CondensedDashboardComponent,
    // CasualDashboardComponent,
    // CorporateDashboardComponent,
    // ExecutiveDashboardComponent,
    // BlankCorporateComponent,
    BlankSimplywhiteComponent,
    BlankCasualComponent,
	TypeComponent,
	PaymentComponent,
	ResultComponent,
	//RenewalComponent,
	DeclinedComponent,
	AcceptedComponent,
	CheckoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardModule,
    pgCardSocialModule,
	RouterModule.forRoot(AppRoutes, {
		onSameUrlNavigation: 'reload',
		enableTracing: false
	  }),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    NvD3Module,
    pgTabsModule,
    NgxEchartsModule,
    IsotopeModule,
    NgxDnDModule,
	NgxPermissionsModule.forRoot(),
    QuillModule.forRoot(),
    PerfectScrollbarModule,
	MembershipsModule,
	//SessionModule,
	VouchersModule,
	DashboardModule,
	ProfileModule,
	UsersModule,
	ClientsModule,
	DetailsModule,
	RenewalModule,
	AppRoutingModule
  ],
  providers: [
    QuickviewService,
    pagesToggleService,
    ChartService,
    SocialService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
	AuthGaurd, RoleGuard, RoleService,
	{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: EncodeHttpParamsInterceptor, multi: true },
	{
	  provide: HAMMER_GESTURE_CONFIG,
	  useClass: AppHammerConfig
	}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
