import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pg-menu-items',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'close',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden'
        })
      ),
      transition('close => open', animate('140ms ease-in')),
      transition('open => close', animate('140ms ease-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, AfterViewInit {
  menuItems = [];
  currentItem = null;
  isPerfectScrollbarDisabled = false;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private authService: AuthService, private router: Router,) {}

  ngOnInit() {}

  hasPermission(perm){
    return this.authService.hasPermission(perm);
  }


//   logout() {
//     this.authService.signout();
//   }

  ngAfterViewInit() {
    setTimeout(() => {
      this.togglePerfectScrollbar();
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.togglePerfectScrollbar();
  }

  togglePerfectScrollbar() {
    this.isPerfectScrollbarDisabled = window.innerWidth < 1025;
  }

  @Input()
  set Items(value) {
    this.menuItems = value;
  }

  toggleNavigationSub(event, item) {
    event.preventDefault();
    if (this.currentItem && this.currentItem !== item) {
      this.currentItem['toggle'] = 'close';
    }
    this.currentItem = item;
    item.toggle = item.toggle === 'close' ? 'open' : 'close';
  }

  
  isActive(url,exact?) {
    let routerUrl = this.router.url.toLowerCase().split("?")[0];
    if(routerUrl.startsWith("/")){
      routerUrl = routerUrl.substring(1,routerUrl.length);
    }
    try{
      if(!exact){
        routerUrl = routerUrl.split("/")[0];
        if(routerUrl.startsWith("/")){
          routerUrl = routerUrl.substring(1,routerUrl.length);
        }
        let isExact = routerUrl == url.toLowerCase();
        if (isExact) {
          return true;
        }
      }
      else{
        if(routerUrl.startsWith("/pender/")){
          routerUrl = routerUrl.substring(1,routerUrl.length);
        }
        let isExact = routerUrl == url.toLowerCase();
        if (isExact) {
          return true;
        }
      }
    }
    catch(err){

    }
    return false;
  }

  childIsActive(item){
    if(item["toggle"] == 'open'){
      return false;
    }
    if(item.submenu){
      for(let menuItem of item.submenu){
        let routerUrl = this.router.url.toLowerCase();
        if(routerUrl.startsWith("/pender/")){
          routerUrl = routerUrl.substring(1,routerUrl.length);
        }
        if(routerUrl == menuItem.routerLink){
          return true;
        }
      }
    }
    return false;
  }

}

