import { EventEmitter } from "@angular/core";
import { ColumnModel } from "../../shared/models/column.model";
import { UsersModel } from "../../shared/models/users.model";

export class UserBuildData{
    module = {
		name: "Users",
		new_name: "users",
		has_dot_menu: true,
		icon: 'fa-street-view',
		permission: 'users'
	}
    
    onReload: EventEmitter < boolean > = new EventEmitter<boolean>();//Forces Reload of codeblack table on emit.
    rowFunction = (row:UsersModel) => {//Generates Row for codeblack-table
        return UsersModel.getListRow(row);
    }
    
    columns:ColumnModel[] = [
        {
            title: "Id",
            prop: "id",
            row_accessor: "id",
            toggle: false
        },
        {
            title: "Name",
            prop: "first_name",
            flex_grow: 2,
            row_accessor: "name"
        },
        {
            title: "Email",
            prop: "last_name",
            flex_grow: 2,
            row_accessor: "email"
        },
    ];

    constructor(){
        for(let col of this.columns){
            col = new ColumnModel(col);
        }
    }

}