import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	constructor(
		private http: HttpClient
	) { }

	getAll(FormData) {
		return this.http.get(`${environment.PendergardensUrl}/product`, FormData);
	}
	getAllPublic(FormData) {
		return this.http.get(`${environment.PendergardensUrl}/public/product`, FormData);
	}

	getProduct(membership, FormData) {
		return this.http.get(`${environment.PendergardensUrl}/product?is_membership=` + membership, FormData);
	}

	getPublicProduct(membership, FormData) {
		return this.http.get(`${environment.PendergardensUrl}/public/product?is_membership=` + membership, FormData);
	}

	getCarType(type, FormData) {
		return this.http.get(`${environment.PendergardensUrl}/product?is_membership=1&vehicle_type=` + type, FormData);
	}

	getPublicCarType(type, FormData) {
		return this.http.get(`${environment.PendergardensUrl}/public/product?is_membership=1&vehicle_type=` + type, FormData);
	}

	create(formData) {
		return this.http.post(`${environment.PendergardensUrl}/product`, formData);
	}

	get(Id) {
		return this.http.get(`${environment.PendergardensUrl}/product/` + Id);
	}

	publicGet(Id) {
		return this.http.get(`${environment.PendergardensUrl}/public/product/` + Id);
	}

	update(Id, formData) {
		return this.http.put(`${environment.PendergardensUrl}/product/` + Id, formData);
	}

	delete(Id) {
		return this.http.delete(`${environment.PendergardensUrl}/product/` + Id);
	}
}
