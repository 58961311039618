import { Routes } from '@angular/router';
import { AuthGaurd } from '../../shared/guards/auth-guard.service';
import { RoleGuard } from '../../shared/guards/role-guard';
import { MembershipsComponent } from './memberships-list/memberships-list.component';


export const MembershipsRoutes: Routes = [
	{
		path: '',
		component: MembershipsComponent
	  },
	];
	