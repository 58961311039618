import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionGroupService {

  constructor(
    private http : HttpClient
  ) { }

    getAll(searchParams){
      return this.http.get(`${environment.baseUrl}/permission/group`,{params:searchParams});
    }

    get(groupId){
      return this.http.get(`${environment.baseUrl}/permission/group/`+groupId);
    }

    edit(groupId,formData){
      return this.http.put(`${environment.baseUrl}/permission/group/`+groupId,formData);
    }

    create(formData){
      return this.http.post(`${environment.baseUrl}/permission/group`,formData);
    }

    delete(groupId){
      return this.http.delete(`${environment.baseUrl}/permission/group/`+groupId);
    }

}
