import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationModel } from '../../../shared/models/notification.model';
import { RootLayout } from '../root/root.component';

@Component({
  selector: 'simplywhite-layout',
  templateUrl: './simplywhite.component.html',
  styleUrls: ['./simplywhite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimplyWhiteLayout extends RootLayout implements OnInit {
  menuLinks = [
    
    {
		label: 'Dashboard',
		details: '',
		iconType: 'fi',
		iconName: 'grid',
		routerLink: 'dashboard',
		roles: 'view_report'
	  },
	  {
		label: 'Memberships',
		roles: 'view_order',
		details: '',
		routerLink: 'memberships',
		iconType: 'fi',
		iconName: 'map'
	  },
	  {
		label: 'Vouchers',
		roles: 'view_order',
		details: '',
		routerLink: 'vouchers',
		iconType: 'fi',
		iconName: 'folder'
	  },
	  {
		label: 'Clients',
		roles: 'view_customer',
		routerLink: 'clients',
		iconType: 'fi',
		iconName: 'users'
	  },
	  {
		label: 'Users',
		routerLink: 'users',
		roles: 'view_user',
		iconType: 'fi',
		iconName: 'user'
	  }
  ];
  ngOnInit() {
	this.filterMenu();
    this.changeLayout('menu-pin');
    //Will sidebar close on screens below 1024
    this.autoHideMenuPin();
  }

//   navigateToPossibleRoute() {
//     for (let menuItem of this.menuLinks) {
//       if (this.authService.hasPermission(menuItem.roles)) {
//         this.router.navigate([menuItem.routerLink]);
//         console.log(menuItem);
//         break;
//       }
//     }
//   }
  
  getAvatar(){
    let user = this.store.getItem("currentUser");
    if(user){
      return user['profile_picture'];
    }
    return "assets/img/avatar.png";
  }

  init() {
    let _this = this;
    // this.changeLayout('menu-pin');
    // this.autoHideMenuPin();
    //this.loadNotifications(false);
    let timeout = setInterval(() => {
      if (_this.authService.authenticated) {
        clearTimeout(timeout);
      }
      else {
        _this.authService.checkAuth(_this.cdr);
      }
    }, 1000);
    // setTimeout(() => {
    //   _this.authService.checkAuth();
    // }, 500);
    if (!this.authService.triggered) {
      this.authService.triggered = true;
      this.authService.initialTimeout = setTimeout(() => {
        _this.authService.initialTimeout = null;
      }, 2000);
    }
  }

  handleSocket(res) {
    console.log(res);
  }

  getNotificationClasses(notification: NotificationModel) {
    let classes: string[] = [];
    classes.push(notification.notification_type);
    return classes;
    //{'unread':!notification.read_at,notification.type};
  }


//   filterMenu() {
//     if (!this.authService.permissions) {
//       if (this.store.getItem('currentUser')) {
//         this.authService.permissions = this.store.getItem('currentUser')['permissions'];
//       }
//     }
//     if (this.authService.permissions) {
//       this.permissionsService.loadPermissions(this.authService.permissions);
//     }
//   }

filterMenu() {
	let _this = this;
    if (!this.authService.permissions) {
      if (this.store.getItem('currentUser')) {
        this.authService.permissions = this.store.getItem('currentUser')['permissions'];
      }
    }
    if (this.authService.permissions) {
      this.permissionsService.loadPermissions(this.authService.permissions);
    }
    let parentIdx = [];
    for (let parent = 0; parent < this.menuLinks.length; parent++) {
      if (!this.authService.hasPermission(this.menuLinks[parent].roles)) {
        parentIdx.push(parent);
      }
    //   if(this.menuLinks[parent].submenu){
    //     let idxList = [];
    //     for(let child = 0; child < this.menuLinks[parent].submenu.length;child++){
    //       if (this.menuLinks[parent].submenu[child]['roles'] && !this.authService.hasPermission(this.menuLinks[parent].submenu[child]['roles'])) {
    //         idxList.push(child);
    //       }
    //     }
    //     idxList.forEach(index => {
    //       delete _this.menuLinks[parent].submenu[index];
    //     });
    //     _this.menuLinks[parent].submenu = _this.menuLinks[parent].submenu.filter(val => val != null);
    //     console.log(_this.menuLinks[parent].submenu);
    //   }
    }
    parentIdx.forEach(index => {
      delete this.menuLinks[index];
    })
    this.menuLinks = this.menuLinks.filter(Boolean);
    
  }

  logout() {
    this.authService.signout();
	
	// console.log("Signing Out!");
    // _this.store.setItem('currentUser', null);
    // _this.router.navigate(['/session/login']);
  }

  isGraceMode() {
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    return this.store.getItem('currentUser')['grace'];
  }

  isExpiredMode() {
    if (this.expiredModeHidden) {
      return null;
    }
    if (!this.store.getItem('currentUser')) {
      return null;
    }
    // if (this.store.getItem('currentUser')['company']['renewal']) {
    //   return this.store.getItem('currentUser')['company']['renewal']['expiry_date'];
    // }
    return null;
  }

  expiredModeHidden: boolean = false;
  hideExpiredMode() {
    this.expiredModeHidden = true;
    let updatedUser = this.store.getItem('currentUser');
    //updatedUser['company']['renewal'] = null;
    this.store.setItem('currentUser', updatedUser);
    console.log(this.store.getItem('currentUser')['company']);
  }

  getUsername() {
    let user = this.store.getItem('currentUser');
    if (!user) {
      return "";
    }
    return user.first_name + ' ' + user.last_name;
  }

  getLogo() {
    return this.authService.getLogo();
  }

  getFavicon() {
    return this.authService.getFavicon();
  }

  isAuthenticated() {
    if (this.authService.initialTimeout || !this.authService.didFirstCheck) {
      return false;
    }
    return this.authService.authenticated;
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  companyProfile() {
    this.router.navigate(['/company/profile']);
  }

  subscriptionModalTrigger: EventEmitter<any> = new EventEmitter<any>();
  openSubscriptionModal() {
    this.subscriptionModalTrigger.emit(true);
  }

  cardModalTrigger: EventEmitter<any> = new EventEmitter<any>();
  openCardModal() {
    this.cardModalTrigger.emit(true);
  }

}
