import { Routes } from '@angular/router';
//Layouts
import {
	BlankComponent,
	SimplyWhiteLayout,
	BlankSimplywhiteComponent,
} from './@pages/layouts';

//Sample Pages
import { DashboardComponent } from './dashboard/simplywhite/dashboard.component';
import { UsersComponent } from './dashboard/users/users-list/users-list.component';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';
import { TypeComponent } from './dashboard/type/type.component';
import { PaymentComponent } from './dashboard/payment/payment.component';
import { DetailsComponent } from './dashboard/details/details.component';
import { CheckoutComponent } from './dashboard/checkout/checkout.component';
import { MembershipsComponent } from './dashboard/memberships/memberships-list/memberships-list.component';
import { ClientsComponent } from './dashboard/clients/clients-list/clients-list.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { UserDetailComponent } from './dashboard/users/users-detail/users-detail.component';
import { ClientDetailComponent } from './dashboard/clients/clients-detail/clients-detail.component';
import { MembershipDetailComponent } from './dashboard/memberships/memberships-detail/memberships-detail.component';
import { ResultComponent } from './dashboard/result/result.component';
import { DeclinedComponent } from './dashboard/declined/declined.component';
import { AcceptedComponent } from './dashboard/accepted/accepted.component';
import { RenewalComponent } from './dashboard/renewal/renewal.component';
import { VoucherComponent } from './dashboard/vouchers/vouchers-list/vouchers-list.component';
import { VoucherDetailComponent } from './dashboard/vouchers/vouchers-detail/vouchers-detail.component';
import { VoucherDetailNewComponent } from './dashboard/vouchers/vouchers-detail-new/vouchers-detail-new.component';
import { MembershipDetailNewComponent } from './dashboard/memberships/memberships-detail-new/memberships-detail-new.component';

export const AppRoutes: Routes = [


	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'dashboard',
				canActivate: [AuthGaurd, RoleGuard],
				
				data: {
					permission: 'view_report'
				},
				component: DashboardComponent
			}
		]
	},
	//Users{
	// {
	// 	path: 'users',
	// 	canActivate: [AuthGaurd,RoleGuard],
	// 	data: {
	// 	  permission:'view_users'
	// 	},
	// 	component: SimplyWhiteLayout,
	// 	loadChildren:() => import('./dashboard/users/users.module').then(m => UsersModule),//'./view/profile/profile.module#ProfileModule',
	// 	runGuardsAndResolvers: 'always'
	// },
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'users',
				canActivate: [AuthGaurd, RoleGuard],
				data: {
					permission: 'view_user'
				},
				component: UsersComponent,
			},
			{
				path: 'users-detail',
				canActivate: [AuthGaurd,RoleGuard],
				// component: UserDetailComponent,
				children: [{
					path: 'new',
					canActivate: [AuthGaurd,RoleGuard],
					component: UserDetailComponent
				  },
				  {
					path: ':id',
					canActivate: [AuthGaurd,RoleGuard],
					component: UserDetailComponent
				  },]
			},
		]
	},
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'clients',
				canActivate: [AuthGaurd, RoleGuard],
				data: {
					permission: 'view_customer'
				},
				component: ClientsComponent,
			},
			{
				path: 'clients-detail',
				canActivate: [AuthGaurd,RoleGuard],
				// component: UserDetailComponent,
				children: [{
					path: 'new',
					canActivate: [AuthGaurd,RoleGuard],
					component: ClientDetailComponent
				  },
				  {
					path: ':id',
					canActivate: [AuthGaurd,RoleGuard],
					component: ClientDetailComponent
				  },]
			},
		]
	},
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'memberships',
				canActivate: [AuthGaurd, RoleGuard],
				data: {
					permission: 'view_order'
				},
				component: MembershipsComponent,
			},
			{
				path: 'memberships-detail',
				canActivate: [AuthGaurd,RoleGuard],
				// component: UserDetailComponent,
				children: [{
					path: 'new',
					canActivate: [AuthGaurd,RoleGuard],
					component: MembershipDetailNewComponent
				  },
				  {
					path: ':id',
					canActivate: [AuthGaurd,RoleGuard],
					component: MembershipDetailComponent
				  },]
			},
		]
	},
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'vouchers',
				canActivate: [AuthGaurd, RoleGuard],
				data: {
					permission: 'view_order'
				},
				component: VoucherComponent,
			},
			{
				path: 'vouchers-detail',
				canActivate: [AuthGaurd,RoleGuard],
				children: [{
					path: 'new',
					canActivate: [AuthGaurd,RoleGuard],
					component: VoucherDetailNewComponent
				  },
				  {
					path: ':id',
					canActivate: [AuthGaurd,RoleGuard],
					component: VoucherDetailComponent
				  },]
			},
		]
	},
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'profile',
				canActivate: [AuthGaurd,RoleGuard],
				component: ProfileComponent
			}
		]
	},

	{
		path: 'session',
		data: {
			permission: ''
		},
		component: BlankSimplywhiteComponent,
		loadChildren: "./session/session.module#SessionModule"
	},
	/////////////////////////////////////////////////////////////////
	{
		path: 'pender',
		component: BlankSimplywhiteComponent,
		children: [
			{
				path: 'type',
				component: TypeComponent
			}
		]
	},
	{
		path: 'pender',
		children: [
			{
				path: 'payment',
				component: PaymentComponent
			}
		]
	},
	{
		path: 'pender',
		component: BlankSimplywhiteComponent,
		children: [
			{
				path: 'details',
				component: DetailsComponent,
				
			}
		]
	},
	{
		path: 'pender',
		component: BlankSimplywhiteComponent,
		children: [
			{
				path: 'renewal',
				component: RenewalComponent
			}
		]
	},
	{
		path: 'pender',
		children: [
			{
				path: 'result',
				component: ResultComponent
			}
		]
	},
	{
		path: 'pender',
		children: [
			{
				path: 'accepted',
				component: AcceptedComponent
			}
		]
	},
	{
		path: 'pender',
		children: [
			{
				path: 'declined',
				component: DeclinedComponent
			}
		]
	},
	{
		path: 'pender',
		component: SimplyWhiteLayout,
		children: [
			{
				path: 'checkout',
				component: CheckoutComponent
			}
		]
	},
	{
		path: '',
		pathMatch: 'full',
		//canActivate: [AuthGaurd, RoleGuard],
		data: {
			permission: ''
		},
		component: SimplyWhiteLayout,
		runGuardsAndResolvers: 'always'
	}
];
